const articles = [
  {
    id: 1,
    slug: "entretien-fetes",
    title: "Préparez votre voiture pour les fêtes de fin d'année 🎄🚗 !",
    subtitle: "Les fêtes approchent à grands pas, et avec elles, les longs trajets pour retrouver vos proches. Avant de prendre la route pour les vacances, assurez-vous que votre véhicule est prêt à affronter l’hiver en toute sécurité !",
    date: "20 décembre 2024",
    image: "/images/actualites/bannieres-fetes.webp",
    subtitle1:"Les éléments à vérifier avant un long trajet :",
    description1: [
      "<strong>Batterie</strong> : Le froid ralentit les réactions chimiques de votre batterie, ce qui peut la rendre moins performante. Un contrôle permet d'éviter la panne au moment du départ.",
      "<strong>Pneus</strong> : Une bonne adhérence est cruciale en hiver ! Vérifiez la pression et l'usure de vos pneus, et pensez aux pneus hiver si vous partez en montagne.",
      "<strong>Freinage</strong> : Des freins usés augmentent la distance d'arrêt, ce qui peut être dangereux en cas de route glissante. Faites-les contrôler avant le départ.",
      "<strong>Liquides essentiels</strong> : Huile moteur, liquide de refroidissement, lave-glace antigel… Ces éléments garantissent le bon fonctionnement de votre voiture même par temps froid.",
      "<strong>Éclairage</strong> : Les journées sont plus courtes, et une bonne visibilité est essentielle pour rouler en toute sécurité. Vérifiez vos phares, feux de stop et clignotants.",
    ],
    subtitle2:"",
    description2: [],
    subtitle3:"",
    description3: "",
    points: [],
    schema: {
      "@context": "https://schema.org",
      "@type": "Article",
      "headline": "Préparez votre voiture pour les fêtes de fin d'année",
      "datePublished": "2024-12-20",
      "image": "https://garageferrand.fr/images/actualites/bannieres-fetes.webp",
      "author": {
        "@type": "Person",
        "name": "Garage Ferrand"
      }
    }
  },
  {
    id: 2,
    slug: "entretien-freinage-securite",
    title: "L'importance d'un bon freinage pour votre sécurité 🚗⚠️",
    subtitle: "Le système de freinage est un élément essentiel pour assurer votre sécurité et celle des autres usagers de la route. Il permet d'arrêter votre véhicule efficacement et d'éviter les accidents. Cependant, avec le temps, les freins s'usent et peuvent perdre en performance.",
    date: "20 décembre 2024",
    image: "/images/mecanicien-remplacement-embrayage.webp",
    subtitle1:"Quand faut-il vérifier ou remplacer ses freins ?",
    description1: [
      "Tous les 20 000 à 30 000 km pour les plaquettes",
      "Tous les 50 000 à 70 000 km pour les disques",
      "Lors de bruits inhabituels (grincement, sifflement)",
      "Si votre véhicule met plus de temps à s'arrêter",
      "Si votre pédale devient trop molle ou trop dure",
    ],
    subtitle2:"Quels sont les signes d'un freinage défaillant ?",
    description2: [
      "✅ Voyant ABS ou frein allumé sur le tableau de bord",
      "✅ Sensation de vibrations dans la pédale de frein",
      "✅ Freinage moins efficace, distance d’arrêt allongée",
      "✅ Bruit métallique ou grincement lors du freinage",
      "✅ Fuite de liquide de frein sous le véhicule",
    ],
    subtitle3:"Pourquoi entretenir régulièrement votre système de freinage ?",
    description3: "Une batterie contient des substances polluantes, comme le plomb et l'acide sulfurique, qui nécessitent un traitement spécifique. En la déposant dans un centre agréé comme chez nous, vous :",
    points: [
      "✔️ Sécurité accrue : Un freinage performant permet d'éviter des accidents.",
      "✔️ Meilleure longévité des pièces : Remplacer les plaquettes à temps évite d'endommager les disques.",
      "✔️ Optimisation du confort de conduite : Un freinage fluide et réactif améliore votre expérience sur la route.",
      "✔️ Conformité au contrôle technique : Un freinage usé peut entraîner un refus au contrôle technique.",
    ],
    schema: {
      "@context": "https://schema.org",
      "@type": "Article",
      "headline": "Votre batterie est en fin de course ? Quand et pourquoi la remplacer ?",
      "datePublished": "2024-12-20",
      "image": "https://garageferrand.fr/images/mecanicien-remplacement-embrayage.webp",
      "author": {
        "@type": "Person",
        "name": "Garage Ferrand"
      }
    }
  },
  {
    id: 3,
    slug: "remplacement-batterie-recyclage",
    title: "Votre batterie est en fin de course ?⚡🔋",
    subtitle: "La batterie de votre véhicule joue un rôle essentiel : elle alimente le moteur au démarrage et fournit de l'énergie aux équipements électroniques (phares, autoradio, climatisation, etc.). Toutefois, avec le temps, elle perd en performance et peut provoquer des démarrages difficiles, voire une panne totale.",
    date: "20 décembre 2024",
    image: "/images/actualites/banniere-batterie.webp",
    subtitle1:"Plusieurs facteurs influencent son usure :",
    description1: [
      "<strong>L'âge de la batterie</strong> : en moyenne, une batterie dure entre 4 et 5 ans.",
      "<strong>Les conditions climatiques</strong> : le froid de l'hiver et la chaleur estivale accélèrent sa détérioration.",
      "<strong>L'utilisation du véhicule</strong> :  les trajets courts empêchent une recharge complète de la batterie.",
      "<strong>Les accessoires énergivores</strong> :  chauffage, phares, GPS… sollicitent davantage votre batterie.",
    ],
    subtitle2:"Quels sont les signes d'une batterie en fin de vie ? Si votre batterie montre un ou plusieurs de ces signes, il est temps d'envisager son remplacement :",
    description2: [
      "✅ Démarrage plus lent que d'habitude",
      "✅ Phares ou éclairage intérieur plus faibles",
      "✅ Témoins lumineux inhabituels sur le tableau de bord",
      "✅ Difficulté à redémarrer après un arrêt prolongé",
      "✅ Perte d'efficacité des équipements électriques (radio, vitres électriques…)",
    ],
    subtitle3:"Pourquoi faire recycler votre batterie chez un professionnel ?",
    description3: "Une batterie contient des substances polluantes, comme le plomb et l'acide sulfurique, qui nécessitent un traitement spécifique. En la déposant dans un centre agréé comme chez nous, vous :",
    points: [
      "✔️ Protégez l'environnement : une batterie usagée peut être recyclée à 95 %."
    ],
    schema: {
      "@context": "https://schema.org",
      "@type": "Article",
      "headline": "Votre batterie est en fin de course ? Quand et pourquoi la remplacer ?",
      "datePublished": "2024-12-20",
      "image": "https://garageferrand.fr/images/actualites/banniere-batterie.webp",
      "author": {
        "@type": "Person",
        "name": "Garage Ferrand"
      }
    }
  },  
];
  
module.exports = articles;
  