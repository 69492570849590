import { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import services from "../../data/services"; 
import AccordionGroup from "../../components/Accordion";
import FaqGroup from "../../components/Faq";
import { HeadsetIcon, PencilEdit02Icon } from "hugeicons-react";

// FILLOUT
import { FilloutPopupEmbed } from "@fillout/react";
import "@fillout/react/style.css";

export default function ServiceDetail() {
    useEffect(() => {
        document.title = `${service.title} - Garage Ferrand`;

        let metaDescription = document.querySelector("meta[name='description']");

        if (metaDescription) {
            metaDescription.setAttribute("content", service.meta);
        } else {
            // Sinon, on la crée et on l'ajoute au <head>
            metaDescription = document.createElement("meta");
            metaDescription.name = "description";
            metaDescription.content = service.meta;
            document.head.appendChild(metaDescription);
        }
    },);

    const { slug } = useParams();

    // Trouver le service correspondant au slug
    const service = services.find((service) => service.slug === slug);

    const [isOpen, setIsOpen] = useState(false);

    if (!service) {
        return <div>Service non trouvé</div>;
    }
    
    return (
        <>
            <main className="md:container md:w-[600px] xl:w-[800px] 2xl:w-[1100px] pt-24 lg:pt-10 mx-10 md:mx-auto flex flex-col items-center">
                <div className="flex flex-col-reverse md:flex-row gap-10 md:gap-20 items-center justify-center mt-10 mb-20">
                    <img src={service.image1} className="animation-img-articles w-2/4" alt="Vue détaillée d'une vidange"/>
                    <div className="md:w-2/4">
                        <h1 className="text-center lg:text-left text-sm mb-4">
                            <a href="/services" className="text-primary">Nos Services</a> {'>'} {service.title}
                        </h1>
                        <div className="bg-white p-4 rounded-xl shadow-md">
                            <h2 className="text-base md:text-xl text-center md:text-left text-primary font-medium mx-10 md:mx-0">{service.subtitle1}</h2>
                            <p className="text-center md:text-left mt-2" dangerouslySetInnerHTML={{ __html: service.description }}></p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row gap-10 md:gap-20 items-center justify-center mb-20">
                    <div className="md:w-2/4">
                        {service.points && service.points.length > 0 ? (
                        <div className="bg-white p-4 rounded-xl shadow-md">
                            <h2 className="text-base md:text-xl text-center md:text-left text-primary font-medium lg:mx-10 md:mx-0">{service.subtitle2}</h2>
                            <ul className="mt-4 list-disc pl-6">
                                {service.points.map((point, index) => (
                                    <li key={index} dangerouslySetInnerHTML={{ __html: point }}></li>
                                ))}
                            </ul>
                        </div>
                    ) : (
                        <div className="mt-6">
                            <h2 className="text-base md:text-xl text-center md:text-left text-primary font-medium mx-10 md:mx-0 mb-4">{service.subtitle3}</h2>
                            <AccordionGroup accordions={service.accordions} />
                        </div>
                    )}
                    </div> 
                    <img src={service.image2} className="animation-img-articles w-2/4" alt="Réalisation d'une vidange sur un véhicule"/>
                </div>

                {(service.points && service.points.length > 0) && (service.accordions && service.accordions.length > 0) && (
                    <div className="mt-6">
                        <h2 className="text-base md:text-xl text-center text-primary font-medium mb-10">{service.subtitle3}</h2> 
                        <AccordionGroup accordions={service.accordions} />
                    </div>
                )}

                <div>
                    <h2 className="text-base md:text-xl text-center text-primary font-medium mx-10 md:mx-0 mb-10">FAQ</h2> 
                    <FaqGroup faq={service.faq} />
                </div>

                {service.subtitle5 ? (
                    <div className="flex flex-col lg:flex-row items-center mb-20 gap-10">
                        <div className="bg-white px-4 py-8 rounded-xl shadow-md flex flex-col items-center justify-between text-center h-[450px] lg:h-96 w-full lg:w-1/2">
                            <HeadsetIcon className="w-16 h-16 text-primary mx-10" aria-label="Icône Téléphone"/>
                            <h2 className="text-base md:text-xl text-primary font-medium lg:mx-10 md:mx-0">{service.subtitle4}</h2>
                            <p dangerouslySetInnerHTML={{ __html: service.description2 }}></p>
                            <a href="tel:+0243455023" className="bg-primary text-white px-6 py-2 rounded-full shadow-lg hover:bg-primary-dark transition lg:text-center">
                                <p>02 43 45 50 23</p>
                            </a>
                        </div>
                        <div className="bg-white px-4 py-8 rounded-xl shadow-md flex flex-col items-center justify-between text-center h-[450px] lg:h-96 w-full lg:w-1/2">
                            <PencilEdit02Icon className="w-16 h-16 text-primary mx-10" aria-label="Icône Commentaire"/>
                            <h2 className="text-base md:text-xl text-primary font-medium lg:mx-10 md:mx-0">{service.subtitle5}</h2>
                            <p>Obtenez votre devis en ligne en quelques clics ! <strong>Simplifiez votre prise de rendez-vous</strong> en réalisant votre devis en ligne. Sélectionnez votre prestation, recevez une estimation rapide et nous planifierons un rendez-vous.</p>
                            <button onClick={() => {
                                if (localStorage.getItem("cookieConsent") === "accepted" && window.gtag) {
                                    window.gtag("event", "open_form", {
                                        event_category: "Formulaire",
                                        event_label: "Popup Fillout ouverte"
                                    });
                                }
                                setIsOpen(true);
                            }} className="bg-primary text-white px-6 py-2 rounded-full shadow-lg hover:bg-primary-dark transition lg:text-center">
                                <p>Commencer le devis</p>
                            </button>
                            
                            {isOpen && (
                                <FilloutPopupEmbed
                                    filloutId="i1DrDgYqhgus"
                                    onClose={() => setIsOpen(false)}
                                />
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="bg-white px-4 lg:px-10 py-8 rounded-xl shadow-md flex flex-col items-center justify-between text-center gap-4 lg:mx-24 mb-20">
                            <HeadsetIcon className="w-16 h-16 text-primary mx-10" aria-label="Icône Téléphone"/>
                            <h2 className="text-base md:text-xl text-primary font-medium lg:mx-10 md:mx-0">{service.subtitle4}</h2>
                            <p className="lg:px-10">{service.description2}</p>
                            <a href="tel:+0243455023" className="bg-primary text-white px-6 py-2 rounded-full shadow-lg hover:bg-primary-dark transition lg:text-center">
                                <p>02 43 45 50 23</p>
                            </a>
                    </div>
                ) 
                }

                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(service.schema) }} />
            </main>
        </>
    );
}