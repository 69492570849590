import { useEffect } from "react";

export default function PolitiqueConfidentialite() {
  useEffect(() => {
    document.title = "Politique de Confidentialité - Garage Ferrand";

    let metaDescription = document.querySelector("meta[name='description']");
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.setAttribute("content", "Découvrez notre politique de confidentialité et la gestion des données personnelles conformément au RGPD.");

  }, []);

  return (
    <div className="pt-16 pb-10 flex flex-col justify-center gap-10 mx-10 lg:mx-56 3xl:mx-96 text-center">
      <h1 className="text-center pb-4 text-xl md:text-2xl text-primary font-semibold">Politique de Confidentialité</h1>

      <p>Chez <strong>Garage Ferrand</strong>, nous attachons une importance particulière à la protection des données personnelles de nos utilisateurs. Cette politique de confidentialité vous explique quelles informations nous collectons, comment nous les utilisons, ainsi que vos droits en matière de protection des données.</p>

      <h2 className="text-lg text-primary font-medium mt-4">1. Responsable du traitement des données</h2>
      <p>Le responsable du traitement des données personnelles collectées sur ce site est : <strong>Garage Ferrand</strong>, situé au <strong>30 rue du Portugal, 72200 Le Bailleul</strong>.
      Pour toute question concernant la protection des données, vous pouvez nous contacter à l'adresse suivante : <strong>ferrand87@orange.fr</strong></p>

      <h2 className="text-lg text-primary font-medium mt-4">2. Données personnelles collectées</h2>
      <p>Nous collectons les informations suivantes :</p>
      <ul className="pl-6 text-center">
          <li>Nom et prénom</li>
          <li>Adresse e-mail</li>
          <li>Numéro de téléphone</li>
          <li>Données de navigation (adresse IP, cookies, pages visitées)</li>
      </ul>

      <h2 className="text-lg text-primary font-medium mt-4">3. Finalités et base légale du traitement</h2>
      <p>Les données collectées sont utilisées pour :</p>
      <ul className="pl-6 text-center">
          <li>Répondre aux demandes des clients</li>
          <li>Envoyer des devis</li>
          <li>Améliorer l'expérience utilisateur et optimiser le site</li>
          <li>Assurer le bon fonctionnement et la sécurité du site</li>
      </ul>
      <p>
          Le traitement des données repose sur l'intérêt légitime du <strong>Garage Ferrand</strong> à assurer la qualité de ses services ainsi que sur votre consentement pour les cookies et le marketing direct.
      </p>

      <h2 className="text-lg text-primary font-medium mt-4">4. Vos droits</h2>
      <p>Conformément au RGPD, vous avez le droit d'accéder, de modifier ou de supprimer vos données personnelles en envoyant une demande à : <strong>ferrand87@orange.fr</strong></p>
    </div>
  );
}