import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Page404() {
    useEffect(() => {
        document.title = "Page introuvable - Garage Ferrand";
        let metaRobots = document.querySelector("meta[name='robots']");
        if (!metaRobots) {
        metaRobots = document.createElement("meta");
        metaRobots.name = "robots";
        document.head.appendChild(metaRobots);
        }
        metaRobots.setAttribute("content", "noindex, follow");
      
    }, []);
    return (
        <div className="flex flex-col items-center justify-center h-screen text-center">
        <h1 className="text-5xl font-bold text-primary">404</h1>
        <h2 className="text-2xl font-semibold mt-4">Oups ! Cette page n'existe pas.</h2>
        <p className="text-gray-600 mt-2">La page que vous recherchez est introuvable.</p>
        <Link
            to="/"
            className="mt-6 px-6 py-3 bg-primary text-white rounded-full shadow-md hover:bg-primary-dark transition"
        >
            Retour à l'accueil
        </Link>
        </div>
    );
}