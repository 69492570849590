import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

// FILLOUT
import { FilloutPopupEmbed } from "@fillout/react";
import "@fillout/react/style.css";

// ICONS
import { FaBars, FaTimes } from "react-icons/fa";
import { PiPhoneCallFill } from "react-icons/pi";

function Header({setHeaderHeight}) {
    const headerRef = useRef(null);
    const [nav, setNav] = useState(false);
    const handleClick = () => setNav(!nav);

    const [isOpen, setIsOpen] = useState(false);

    const isActive = (path) => window.location.pathname === path;

    useEffect(() => {
        // Calcule la hauteur du header
        if (headerRef.current) {
            setHeaderHeight(headerRef.current.offsetHeight);
        }

        const handleResize = () => {
            if (headerRef.current) {
                setHeaderHeight(headerRef.current.offsetHeight);
            }
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [setHeaderHeight]);

    useEffect(() => {
        if (nav) {
            document.body.style.overflow = "hidden"; // Empêche le scroll
        } else {
            document.body.style.overflow = ""; // Réactive le scroll
        }
        return () => {
            document.body.style.overflow = ""; // Réinitialise si le composant est démonté
        };
    }, [nav]);

    return (
        <header ref={headerRef} className="fixed lg:scroll left-0 right-0 max-w-screen bg-[#fff] py-8 z-50 shadow-md lg:relative">
            {/* NAVIGATION TOP */}
            <div className="bg-primary flex items-center justify-between px-4 lg:px-24 h-10 lg:h-12">
                {/* LOGO & SLOGAN */}
                <div className="relative">
                    <img src="/images/bosch-car-service-logo.svg" alt="Logo Bosch Car Service" className="w-14 lg:w-20 absolute -top-4 md:-top-5"/>
                    <span className="pl-16 lg:pl-24 text-white text-[10px] lg:text-sm font-bold">Nous faisons tout pour votre voiture</span>
                </div>

                {/* TELEPHONE & DEVIS */}
                <div className="flex items-center gap-2">
                    <a href="tel:+0243455023" className="hidden lg:flex items-center gap-2 bg-white text-primary rounded-full px-4 py-1 hover:cursor-pointer">
                        <PiPhoneCallFill aria-label="Icône Téléphone"/>
                        <p>02 43 45 50 23</p>
                    </a>
                    <button onClick={() => {
                        if (localStorage.getItem("cookieConsent") === "accepted" && window.gtag) {
                            window.gtag("event", "open_form", {
                                event_category: "Formulaire",
                                event_label: "Popup Fillout ouverte"
                            });
                        }
                        setIsOpen(true);
                    }} className="hidden lg:flex items-center gap-2 bg-white text-primary rounded-full px-4 py-1 hover:cursor-pointer">
                        <p>Devis gratuit</p>
                    </button>

                    {isOpen && (
                        <FilloutPopupEmbed
                            filloutId="i1DrDgYqhgus"
                            onClose={() => setIsOpen(false)}
                        />
                    )}

                    <div onClick={handleClick} className='lg:hidden z-10'>
                        {!nav ? <FaBars className='text-2xl text-white' aria-label="Icône Menu"/> : <FaTimes className='text-2xl text-primary' aria-label="Icône Croix"/>}
                    </div>
                </div>
            </div>

            {/* NAVIGATION BOTTOM */}
            <nav className="hidden lg:flex justify-center pt-10">
                <ul className="flex items-center md:gap-8 lg:gap-10">
                    <li><Link to="/" className={`border-primary border shadow-lg rounded-full md:px-6 md:py-1 lg:px-10 lg:py-2 hover:bg-primary hover:text-white transition ${
                                isActive("/") ? "bg-primary text-white" : "text-primary"
                            }`}>Accueil</Link>
                    </li>
                    <li><Link to="/services" className={`border-primary border shadow-lg rounded-full md:px-6 md:py-1 lg:px-10 lg:py-2 hover:bg-primary hover:text-white transition ${
                                isActive("/services") ? "bg-primary text-white" : "text-primary"
                            }`}>Nos services</Link>
                    </li>
                    <li><Link to="/actualites" className={`border-primary border shadow-lg rounded-full md:px-6 md:py-1 lg:px-10 lg:py-2 hover:bg-primary hover:text-white transition ${
                                isActive("/actualites") ? "bg-primary text-white" : "text-primary"
                            }`}>Nos actualités</Link>
                    </li>
                    <li><Link to="/contact" className={`border-primary border shadow-lg rounded-full md:px-6 md:py-1 lg:px-10 lg:py-2 hover:bg-primary hover:text-white transition ${
                                isActive("/contact") ? "bg-primary text-white" : "text-primary"
                            }`}>Nous contacter</Link>
                    </li>
                </ul>
            </nav>

            <ul className={!nav ? 'hidden' : 'absolute z-0 top-0 left-0 w-full h-screen bg-white flex flex-col gap-4 justify-center items-center'}>
                <li><Link to="/" onClick={handleClick}><img src="/images/bosch-car-service-logo.svg" alt="Logo Bosch Car Service" className="w-16"/></Link></li>
                <li className='text-2xl font-medium'><Link to="/" onClick={handleClick} className="text-primary">Accueil</Link></li>
                <li className='text-2xl font-medium'><Link to="/services" onClick={handleClick} className="text-primary">Nos services</Link></li>
                <li className='text-2xl font-medium'><Link to="/actualites" onClick={handleClick} className="text-primary">Nos actualités</Link></li>
                <li className='text-2xl font-medium'><Link to="/contact" onClick={handleClick} className="text-primary">Nous contacter</Link></li>
                <li><a href="tel:+0243455023" className="flex items-center gap-2 text-white bg-primary rounded-full px-6 py-2"><PiPhoneCallFill aria-label="Icône Téléphone"/><p>02 43 45 50 23</p></a></li>
                <li><button onClick={() => setIsOpen(true)} className="flex items-center text-white bg-primary rounded-full px-6 py-2">
                        <p>Devis gratuit</p>
                    </button>

                    {isOpen && (
                        <FilloutPopupEmbed
                            filloutId="i1DrDgYqhgus"
                            onClose={() => setIsOpen(false)}
                        />
                    )}</li>
            </ul>
        </header>
    )
}

export default Header;