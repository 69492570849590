import { IoMail, IoLocationSharp } from "react-icons/io5";
import { PiPhoneCallFill } from "react-icons/pi";

function Footer () {
    return (
        <div>
            <div className="bg-white pt-12 flex flex-col md:flex-row gap-20 items-center justify-around py-8 px-10 md:px-40">
                <div className="flex flex-col text-center items-center md:items-start justify-around gap-4 w-full md:w-2/4">
                    <img src="/images/bosch-car-service-logo.svg" alt="Logo Bosch Car Service" className="w-20 mb-4"/>
                    <a href="https://www.google.fr/maps/place/Garage+Pascal+Ferrand/@47.7733533,-0.2146748,17z/data=!4m6!3m5!1s0x481117e7ef5eb399:0x53afc46dbd157bbd!8m2!3d47.7733497!4d-0.2120999!16s%2Fg%2F1tdq0h_y?entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D" target='_blank' rel="noreferrer" className="flex md:items-center gap-2">
                        <IoLocationSharp className="text-primary text-2xl md:text-xl" aria-label="Icône Localisation"/>
                        <p>30 rue Portugal Zac Ouest Parc, 72200 le Bailleul</p>
                    </a>
                    <a href="tel:0243455023" className="flex md:items-center gap-2">
                        <PiPhoneCallFill className="text-primary text-xl" aria-label="Icône Téléphone"/>
                        <p>02 43 45 50 23</p>
                    </a>
                    <a href="mailto:ferrand87@orange.fr" target='_blank' rel="noreferrer" className="flex md:items-center gap-2">
                        <IoMail className="text-primary text-xl" aria-label="Icône Email"/>
                        <p>ferrand84@orange.fr</p>
                    </a>
                </div>
                <div className="flex flex-col items-center md:items-start justify-around gap-4 w-full md:w-1/4">
                    <h3 className="text-primary font-bold">Liens</h3>
                    <a href="/">Accueil</a>
                    <a href="/services">Nos services</a>
                    <a href="/actualites">Nos actualités</a>
                    <a href="/contact">Nous contacter</a>
                </div>
                <div className="flex flex-col items-center md:items-start justify-around gap-4 w-full md:w-1/4">
                    <h3 className="text-primary font-bold">Informations</h3>
                    <a href="/mentions-legales">Mentions légales</a>
                    <a href="/politique-confidentialite">Politique de confidentialité</a>
                    <a href="/cgu">Conditions générale d'utilisation</a>
                    <a href="https://www.boschcarservice.com/fr/fr" target="_blank" rel="noreferrer">Bosch Car Service</a>
                </div>
            </div>
            
            <div className="flex items-center justify-center bg-primary text-white py-4 md:py-2 px-10">
                <p>Copyright © 2024 | <a href="https://evaferrand.fr" target="_blank" rel="noreferrer">Eva Ferrand</a></p>
            </div>
        </div>
    );
}

export default Footer;