import { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";

const CookieConsent = () => {
    const [consent, setConsent] = useState(localStorage.getItem("cookieConsent"));

    useEffect(() => {
        if (consent === "accepted") {
            loadGoogleAnalytics();
        }
    }, [consent]);

    const handleAccept = () => {
        setConsent("accepted");
        localStorage.setItem("cookieConsent", "accepted");
    };

    const handleDecline = () => {
        setConsent("declined");
        localStorage.setItem("cookieConsent", "declined");
    };

    const loadGoogleAnalytics = () => {
        if (!window.gtag) {
            const script = document.createElement("script");
            script.src = "https://www.googletagmanager.com/gtag/js?id=G-51TY39ECZJ";
            script.async = true;
            document.head.appendChild(script);

            script.onload = () => {
                window.dataLayer = window.dataLayer || [];
                function gtag() { window.dataLayer.push(arguments); }
                window.gtag = gtag;
                gtag("js", new Date());
                gtag("config", "G-51TY39ECZJ");
            };
        }
    };

    if (consent) return null;

    return (
        <div className="fixed bottom-0 lg:bottom-10 lg:right-10 w-full lg:w-1/3 bg-white p-4 rounded-lg z-50 shadow-md">
            <div className="flex justify-end mb-4 items-center gap-4">
                <button onClick={handleDecline} className="text-primary cursor-pointer underline text-sm">Continer sans accepter</button>
                <FaArrowRight className="text-primary text-sm"/>
            </div>

            <div className="text-center flex flex-col justify-center items-center gap-4">
                <p>Avec votre accord, nous utilisons des cookies pour améliorer l'expérience utilisateur et analyser le trafic. Vous pouvez, à toout moment, retirer votre consentement en cliquant sur <strong>"En savoir plus"</strong> ou en allant dans notre politique de confidentialité sur ce site internet.</p>
                <div className="flex gap-4">
                    <a href="/politique-confidentialite" className="text-primary border-2 border-primary py-2 px-6 cursor-pointer font-bold rounded-full flex items-center gap-4">En savoir plus <FaArrowRight className="text-primary text-sm"/></a>
                    <button onClick={handleAccept} className="bg-primary text-white border-none py-2 px-6 cursor-pointer font-bold rounded-full">Accepter</button>
                </div>
            </div>
        </div>
    );
};

export default CookieConsent;
