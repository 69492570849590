const services = [
    // Service 1 -> Révision / Vidange
    {
        id: 1,
        slug: "revision-vidange",
        meta: "Profitez d'un entretien optimal avec notre service de vidange et remplacement des filtres au Garage Ferrand. Préservez la longévité et les performances de votre moteur.",
        title: "Révision / Vidange",
        subtitle1: "Entretien régulier chez Bosch Car Service",
        description: "Nous savons que la révision et la vidange régulières de votre véhicule sont essentielles pour <strong>assurer son bon fonctionnement</strong> et <strong>prolonger sa durée de vie</strong>. Nos techniciens spécialisés effectuent un contrôle complet de votre véhicule lors de chaque révision pour <strong>garantir une performance optimale</strong>.",
        subtitle2: "Pourquoi faire une révision régulière de votre véhicule ?",
        points: [
            "<strong>Prévenir les pannes :</strong> Une révision permet d'identifier les signes avant-coureurs de problèmes mécaniques et d'éviter des réparations coûteuses.",
            "<strong>Améliorer la sécurité :</strong> Nous vérifions les éléments essentiels comme les freins, les pneus, les suspensions et l'éclairage pour garantir votre sécurité sur la route.",
            "<strong>Optimiser la consommation de carburant :</strong> Une vidange et un entretien moteur réguliers permettent à votre véhicule de consommer moins de carburant en améliorant son efficacité.",
            "<strong>Prolonger la durée de vie du moteur :</strong> En changeant régulièrement l'huile moteur, vous réduisez l'usure et la friction des composants internes, garantissant ainsi une meilleure longévité de votre véhicule.",
        ],
        subtitle3: "Nos services de révision et de vidange comporte :",
        accordions: [
            {
                question: (
                    "Vidange et remplacement du filtre à huile"
                ),
                answer: (
                    "Nous utilisons une <strong>huile de haute qualité</strong> pour assurer le <strong>bon fonctionnement du moteur</strong> tout en respectant les normes du constructeur."
                )
            },
            {
                question: (
                    "Contrôle des niveaux"
                ),
                answer: (
                    "Nos techniciens <strong>vérifient et ajustent les niveaux des liquides (frein, refroidissement, direction assistée, lave-glace)</strong> pour une conduite en toute tranquillité."
                )
            },
            {
                question: (
                    "Vérification des éléments de sécurité"
                ),
                answer: (
                    "<strong>Inspection des freins, amortisseurs, pneus et éclairage</strong> pour garantir un véhicule sûr et conforme aux normes en vigueur."
                )
            },
            {
                question: (
                    "Contrôle des filtres"
                ),
                answer: (
                    "<strong>Remplacement des filtres à air, à carburant et habitacle</strong> si nécessaire, pour améliorer la performance du moteur et la qualité de l'air dans l'habitacle."
                )
            },
        ],
        faq: [
            {
                question: (
                    "<strong>Q:</strong> À quelle fréquence dois-je faire la vidange de mon véhicule ?"
                ),
                answer: (
                    "<strong>R:</strong> La vidange est généralement recommandée <strong>tous les 10 000 à 15 000 kilomètres</strong> ou <strong>une fois par an</strong>, selon votre usage."
                )
            },
            {
                question: (
                    "<strong>Q:</strong> Quels sont les signes que mon véhicule a besoin d'une vidange ?"
                ),
                answer: (
                    "<strong>R:</strong> Des signes tels qu'un <strong>moteur plus bruyant</strong>, une <strong>perte de performance</strong>, ou une <strong>consommation accrue de carburant</strong> peuvent indiquer qu'une vidange est nécessaire. Il est essentiel de ne pas négliger ces signaux pour éviter une usure prématurée du moteur."
                )
            },
            {
                question: (
                    "<strong>Q:</strong> En quoi consiste une révision complète de véhicule ?"
                ),
                answer: (
                    "<strong>R:</strong> Une révision complète comprend la <strong>vidange d'huile</strong>, le <strong>changement des filtres (air, huile, carburant, habitacle)</strong>, le <strong>contrôle des niveaux de liquide</strong>, la <strong>vérification des freins, pneus et suspensions</strong>."
                )
            },
        ],
        subtitle4: "Prenez rendez-vous pour la révision et la vidange",
        description2:"Assurez-vous que votre moteur fonctionne de manière optimale <strong>en effectuant régulièrement la révision et la vidange</strong> de votre véhicule. Contactez-nous dès aujourd'hui pour entretenir votre véhicule.",
        subtitle5: "Demandez un devis pour la révision et la vidange de votre véhicule",
        icon: "/images/vidange.webp",
        image1: "/images/vidange-1.webp",
        image2: "/images/vidange-2.webp",
        schema: {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Vidange et Entretien",
            "provider": {
              "@type": "AutomotiveBusiness",
              "name": "Garage Ferrand"
            },
            "description": "Service de vidange avec remplacement des filtres pour éviter l'encrassement du moteur.",
            "areaServed": {
              "@type": "Place",
              "name": "Le Bailleul"
            }
        }
    },
    // Service 2 -> Diagnostic
    {
        id: 2,
        slug: "diagnostic",
        meta: "Voyant moteur allumé ? Nos outils de diagnostic électronique détectent rapidement les pannes de votre véhicule. Passez au Garage Ferrand pour un contrôle précis.",
        title: "Diagnostic",
        subtitle1: "Diagnostic automobile chez Bosch Car Service",
        description: "Le <strong>diagnostic automobile</strong> est une <strong>étape cruciale</strong> pour garantir le bon fonctionnement de votre véhicule. Que vous remarquiez des <strong>signes d'alerte</strong> ou souhaitiez simplement vous assurer que <strong>tout fonctionne correctement</strong>, notre équipe de mécaniciens spécialisés est là pour vous.",
        subtitle2: "Pourquoi faire un diagnostic automobile ?",
        points: [
            "<strong>Prévenir des pannes coûteuses :</strong> Un diagnostic régulier peut détecter des problèmes mineurs avant qu'ils ne s'aggravent, vous permettant d'économiser sur des réparations plus importantes.",
            "<strong>Assurer votre sécurité :</strong> Un diagnostic peut identifier des défaillances critiques, telles que des problèmes de freinage ou de direction, qui pourraient mettre en danger votre sécurité et celle des autres usagers de la route.",
            "<strong>Optimiser les performances :</strong> En identifiant des éléments défectueux comme des capteurs ou des systèmes de gestion moteur, nous pouvons optimiser les performances de votre véhicule pour une conduite plus fluide et plus économique.",
        ],
        subtitle3: "Notre service de diagnostic comporte :",
        accordions: [
            {
                question: (
                    "Diagnostic moteur"
                ),
                answer: (
                    "<strong>Le moteur est le cœur de votre véhicule</strong>, et un problème au niveau du moteur peut affecter l'ensemble de votre voiture. Nos équipements nous permettent de scanner le système de gestion moteur et d'identifier des anomalies comme des <strong>capteurs défaillants</strong>, des <strong>dysfonctionnements dans le système de carburant</strong>, ou des <strong>problèmes d'allumage</strong>."
                )
            },
            {
                question: (
                    "Diagnostic électrique"
                ),
                answer: (
                    "Les problèmes électriques sont souvent difficiles à détecter sans un équipement spécialisé. Nous analysons l'ensemble du système électrique de votre véhicule, incluant la <strong>batterie</strong>, les <strong>phares</strong>, les <strong>feux de signalisation</strong>, ainsi que les éléments de confort comme la <strong>climatisation</strong> et les <strong>vitres électriques</strong>."
                )
            },
            {
                question: (
                    "Diagnostic électronique"
                ),
                answer: (
                    "Les véhicules modernes sont équipés de nombreux systèmes électroniques qui nécessitent une attention particulière. Nous vérifions les <strong>systèmes d'assistance à la conduite (ABS, ESP)</strong>, les <strong>airbags</strong>, et les <strong>systèmes de gestion des performances</strong> pour nous assurer qu'ils fonctionnent comme prévu."
                )
            },
            {
                question: (
                    "Lecture des codes d'erreurs OBD"
                ),
                answer: (
                    "En cas de panne, <strong>votre voiture enregistre des codes d'erreurs via le système OBD (On-Board Diagnostics)</strong>. Nos techniciens lisent et interprètent ces codes pour diagnostiquer rapidement les problèmes liés au moteur, à la transmission, ou à d'autres composants essentiels."
                )
            },
            {
                question: (
                    "Diagnostic avant contrôle technique"
                ),
                answer: (
                    "Si votre véhicule doit passer un <strong>contrôle technique</strong>, nous proposons un <strong>diagnostic</strong> complet pour nous assurer qu'il est en état de réussir le test. Nous identifions les points critiques qui pourraient entraîner un échec et les corrigeons avant la visite."
                )
            },
        ],
        faq: [
            {
                question: (
                    "<strong>Q:</strong> Que faire si une lumière s'allume sur mon tableau de bord ?"
                ),
                answer: (
                    "<strong>R:</strong> Si un témoin lumineux s'allume sur votre tableau de bord, il est important de ne pas l'ignorer. <strong>Prenez rendez-vous rapidement pour un diagnostic</strong> afin de déterminer la cause et <strong>éviter des dommages plus graves</strong>."
                )
            },
            {
                question: (
                    "<strong>Q:</strong> À quelle fréquence dois-je faire un diagnostic ?"
                ),
                answer: (
                    "<strong>R:</strong> Il est conseillé de faire un diagnostic <strong>au moins une fois par an</strong>, ou <strong>avant tout long trajet</strong>, pour s'assurer que votre véhicule est en bon état. De plus, si vous remarquez des <strong>signes inhabituels (bruits, perte de puissance)</strong>, un diagnostic est recommandé immédiatement."
                )
            },
            {
                question: (
                    "<strong>Q:</strong> Combien de temps dure un diagnostic automobile ?"
                ),
                answer: (
                    "<strong>R:</strong> Un diagnostic complet prend généralement entre <strong>30 minutes et 1 heure</strong>, selon la complexité des systèmes à vérifier. Nos techniciens vous tiendront informé de chaque étape."
                )
            },
        ],
        subtitle4: "Prenez rendez-vous pour un diagnostic complet",
        description2:"Si vous remarquez des problèmes sur votre véhicule ou souhaitez simplement vous assurer que tout est en ordre, <strong>n'attendez pas que la situation empire</strong>. Contactez-nous dès aujourd'hui pour entretenir votre véhicule.",
        subtitle5: "Demandez un devis pour réalisé un diagnostic",
        icon: "/images/diagnostic.webp",
        image1: "/images/mecanicien-diagnostic-moteur.webp",
        image2: "/images/valise-diagnostic.webp",
        schema: {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Diagnostic",
            "provider": {
              "@type": "AutomotiveBusiness",
              "name": "Garage Ferrand"
            },
            "description": "Service de diagnostic.",
            "areaServed": {
              "@type": "Place",
              "name": "Le Bailleul"
            }
        }
    },
    // Service 3 -> Climatisation
    {
        id: 3,
        slug: "climatisation",
        meta: "Roulez confortablement en toute saison grâce à notre service de recharge et d'entretien de climatisation auto. Rendez-vous au Garage Ferrand pour un diagnostic complet.",
        title: "Climatisation",
        subtitle1: "Entretien et Recharge chez Bosch Car Service",
        description: "La climatisation de votre véhicule est essentielle pour votre confort, surtout pendant les chaudes journées d'été. Au fil du temps, elle perd de son efficacité et peut même causer des problèmes si elle n'est pas entretenue correctement. Chez Garage Ferrand, nous proposons des services complets d'<strong>entretien</strong> et de <strong>recharge</strong> de <strong>climatisation</strong> pour garantir que votre système fonctionne toujours de manière optimale.",
        subtitle2: "Quand recharger ou entretenir votre climatisation ?",
        points: [
            "<strong>Tous les 2 ans :</strong> Il est recommandé de faire vérifier et recharger la climatisation de votre véhicule tous les deux ans pour maintenir ses performances.",
            "<strong>Signes de dysfonctionnement :</strong> Si vous remarquez une diminution de la puissance de refroidissement, des bruits inhabituels, ou des odeurs désagréables, il est temps de faire vérifier votre système par un professionnel.",
        ],
        subtitle3: "Notre service de climatisation comporte :",
        accordions: [
            {
                question: (
                    "Recharge de fluide réfrigérant"
                ),
                answer: (
                    "La <strong>recharge</strong> est une étape essentielle pour assurer un fonctionnement optimal de votre climatisation. Nous utilisons les deux types de fluides les plus courants : <strong>R134A</strong> pour les véhicules plus anciens et <strong>R1234YF</strong> pour les modèles plus récents, respectueux de l'environnement."
                )
            },
            {
                question: (
                    "Entretien complet de la climatisation"
                ),
                answer: (
                    "Nous effectuons un contrôle minutieux de l'ensemble du système de climatisation, incluant : la <strong>vérification des filtres</strong> et du fluide réfrigérant, le <strong>nettoyage</strong> du circuit de climatisation pour éliminer les <strong>bactéries</strong> et prévenir les mauvaises odeurs et l'<strong>inspection des compresseurs, condenseurs</strong>, et autres composants pour détecter tout dysfonctionnement."
                )
            },
            {
                question: (
                    "Diagnostic et réparation de climatisation"
                ),
                answer: (
                    "Si vous rencontrez des problèmes avec votre climatisation, notre équipe d'experts est à votre disposition pour diagnostiquer et <strong>réparer les pannes</strong>, qu'il s'agisse de <strong>fuites</strong>, de <strong>compresseurs défectueux</strong> ou de tout autre problème technique."
                )
            },
        ],
        faq: [
            {
                question: (
                    "<strong>Q:</strong> À quelle fréquence dois-je recharger ma climatisation ?"
                ),
                answer: (
                    "<strong>R:</strong> Il est recommandé de vérifier et de recharger le fluide de climatisation <strong>tous les deux ans</strong> pour maintenir des performances optimales.."
                )
            },
            {
                question: (
                    "<strong>Q:</strong> Que faire si ma climatisation émet une mauvaise odeur ?"
                ),
                answer: (
                    "<strong>R:</strong> Une odeur désagréable peut indiquer la <strong>présence de bactéries dans le système</strong>. Nous recommandons un nettoyage complet de votre circuit de climatisation pour éliminer ces problèmes."
                )
            },
            {
                question: (
                    "<strong>Q:</strong> Quels sont les signes que ma climatisation a besoin d’un entretien ?"
                ),
                answer: (
                    "<strong>R:</strong> Si vous remarquez une <strong>baisse de la puissance</strong> de refroidissement, des <strong>bruits inhabituels</strong> ou des <strong>odeurs désagréables</strong>, il est temps de faire contrôler votre climatisation."
                )
            },
        ],
        subtitle4: "Prenez rendez-vous pour entretenir votre climatisation",
        description2:"<strong>N'attendez pas que votre climatisation tombe en panne avant de la faire entretenir</strong>. Contactez-nous dès aujourd'hui pour un diagnostic rapide et une recharge de votre système de climatisation.",
        subtitle5: "Demandez un devis pour entretenir votre climatisation",
        icon: "/images/climatisation.webp",
        image1: "/images/entretien-climatisation.webp",
        image2: "/images/mecanicien-entretien-climatisation.webp",
        schema: {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Climatisation",
            "provider": {
              "@type": "AutomotiveBusiness",
              "name": "Garage Ferrand"
            },
            "description": "Service de climatisation.",
            "areaServed": {
              "@type": "Place",
              "name": "Le Bailleul"
            }
        }
    },
    // Service 4 -> Pneumatique
    {
        id: 4,
        slug: "pneumatique",
        meta: "Assurez votre sécurité avec des pneus en parfait état. Montage, équilibrage et réparation de pneus au Garage Ferrand, spécialiste de l’entretien automobile.",
        title: "Pneumatique",
        subtitle1: "Vérification, Entretien et Remplacement",
        description: "Les pneus de votre véhicule jouent un rôle crucial dans votre <strong>sécurité</strong> et votre <strong>confort de conduite</strong>. Une <strong>usure excessive</strong> ou des <strong>pneus mal entretenus</strong> peuvent non seulement compromettre la <strong>tenue de route</strong>, mais aussi augmenter la <strong>consommation de carburant</strong>.",
        subtitle2: "Quand changer vos pneus ?",
        points: [
            "<strong>Profondeur de la bande de roulement inférieure à 1,6 mm :</strong> Si la profondeur de la bande de roulement de vos pneus est inférieure à 1,6 mm, il est temps de les changer pour garantir une bonne adhérence sur la route.",
            "<strong>Usure irrégulière :</strong> Si vous constatez une usure inégale de vos pneus, cela peut indiquer un problème d'alignement ou de suspension. Nous vous conseillons de faire vérifier vos pneus immédiatement.",
            "<strong>Âge des pneus :</strong> Même si vos pneus semblent encore en bon état, il est recommandé de les changer tous les 5 à 6 ans pour éviter des risques de défaillance.",
        ],
        subtitle3: "Notre service de pneumatique comporte :",
        accordions: [
            {
                question: (
                    "Vérification et ajustement de la pression des pneus"
                ),
                answer: (
                    "Des pneus <strong>sous-gonflés</strong> ou <strong>surgonflés</strong> peuvent entraîner une <strong>usure prématurée</strong> et réduire l'efficacité de votre véhicule. Nous vérifions régulièrement la pression de vos pneus et ajustons celle-ci pour garantir une conduite plus sûre et plus économique."
                )
            },
            {
                question: (
                    "Inspection de l'usure des pneus"
                ),
                answer: (
                    "Une <strong>usure inégale ou excessive</strong> des pneus peut être le signe de problèmes mécaniques tels que des <strong>désalignements ou des amortisseurs défectueux</strong>. Nous réalisons une inspection complète de l'usure de vos pneus pour déterminer si un remplacement est nécessaire et vous conseiller sur les meilleures options."
                )
            },
            {
                question: (
                    "Réparation de pneus"
                ),
                answer: (
                    "En cas de <strong>crevaison</strong> ou de <strong>dommage mineur</strong>, nous proposons des <strong>services de réparation rapide</strong> pour vous éviter le remplacement immédiat de vos pneus."
                )
            },
            {
                question: (
                    "Remplacement de pneus"
                ),
                answer: (
                    "Si vos pneus sont <strong>trop usés</strong> ou <strong>endommagés</strong>, nous vous proposons un <strong>service de remplacement</strong> avec un large choix de marques et de modèles."
                )
            },
            {
                question: (
                    "Équilibrage des roues"
                ),
                answer: (
                    "L'<strong>équilibrage des roues</strong> est essentiel pour assurer une <strong>usure uniforme</strong> de vos pneus et une <strong>conduite sans vibrations</strong>. Nous équilibrons soigneusement vos roues lors de chaque remplacement ou réparation de pneus afin de garantir une conduite stable et confortable."
                )
            },
        ],
        faq: [
            {
                question: (
                    "<strong>Q:</strong> À quelle fréquence dois-je vérifier la pression de mes pneus ?"
                ),
                answer: (
                    "<strong>R:</strong> Il est recommandé de vérifier la pression de vos pneus <strong>tous les mois</strong> ou <strong>avant un long trajet</strong>."
                )
            },
            {
                question: (
                    "<strong>Q:</strong> Comment savoir si mes pneus sont usés ?"
                ),
                answer: (
                    "<strong>R:</strong> Si la profondeur de la bande de roulement est <strong>inférieure à 1,6 mm</strong>, vos pneus sont trop usés et doivent être remplacés."
                )
            },
            {
                question: (
                    "<strong>Q:</strong> Que faire en cas de crevaison ?"
                ),
                answer: (
                    "<strong>R:</strong> <strong>En cas de crevaison, venez nous voir au Garage Ferrand</strong>. Nous déterminerons si le pneu peut être réparé ou s'il doit être remplacé."
                )
            },
        ],
        subtitle4: "Prenez rendez-vous pour vos pneus",
        description2:"Que vous ayez besoin d'une vérification de la pression, d'un remplacement de pneus ou d'une réparation rapide, nous sommes à votre disposition. ",
        subtitle5: "Demandez un devis pour changer vos pneus",
        icon: "/images/pneumatique.webp",
        image1: "/images/mecanicien-enleve-pneu.webp",
        image2: "/images/mecanicien-met-pneu.webp",
        schema: {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Pneumatique",
            "provider": {
              "@type": "AutomotiveBusiness",
              "name": "Garage Ferrand"
            },
            "description": "Service de pneumatique.",
            "areaServed": {
              "@type": "Place",
              "name": "Le Bailleul"
            }
        }
    },
    // Service 5 -> Freinage
    {
        id: 5,
        slug: "freinage",
        meta: "Vos freins sont-ils encore efficaces ? Faites vérifier et remplacer vos plaquettes et disques de frein au Garage Ferrand pour une sécurité maximale sur la route.",
        title: "Freinage",
        subtitle1: "Réparation et Entretien de votre système de freinage",
        description: "Le système de freinage est <strong>l'un des éléments les plus cruciaux</strong> pour <strong>assurer votre sécurité</strong> sur la route. Des <strong>freins usés</strong> ou <strong>défectueux</strong> peuvent augmenter considérablement les <strong>risques d'accidents</strong> en allongeant les distances de freinage ou en rendant le véhicule difficile à contrôler.",
        subtitle2: "Signes que vos freins ont besoin d'entretien :",
        points: [
            "<strong>Bruits inhabituels :</strong> Des bruits de grincement ou de frottement lors du freinage peuvent indiquer des plaquettes de frein usées ou des disques de frein endommagés.",
            "<strong>Vibrations :</strong> Si vous ressentez des vibrations dans le volant ou les pédales lorsque vous freinez, cela pourrait être dû à des disques de frein voilés ou à un problème d'alignement des étriers.",
            "<strong>Pédale de frein molle :</strong> Si votre pédale de frein devient molle ou nécessite une pression excessive pour freiner, cela peut être dû à une fuite de liquide de frein ou à des problèmes au niveau du système hydraulique.",
            "<strong>Allongement de la distance de freinage :</strong> Si vous remarquez que votre véhicule prend plus de temps pour s'arrêter, il est probable que vos freins soient usés et qu'ils nécessitent une intervention immédiate.",
        ],
        subtitle3: "Notre service de freinage comporte :",
        accordions: [
            {
                question: (
                    "Contrôle et inspection des freins"
                ),
                answer: (
                    "Nous effectuons une <strong>inspection complète du système de freinage</strong>, incluant les <strong>plaquettes de frein</strong>, les <strong>disques</strong>, les <strong>étriers</strong>, et le <strong>liquide de frein</strong>. Nous vérifions leur usure, leur efficacité et leur état général pour déterminer si des réparations ou des remplacements sont nécessaires."
                )
            },
            {
                question: (
                    "Remplacement des plaquettes de frein"
                ),
                answer: (
                    "Les plaquettes de frein sont les composants qui <strong>s'usent le plus rapidement dans le système de freinage</strong>. Si elles sont trop usées, elles ne seront <strong>plus efficaces</strong> et <strong>pourraient endommager vos disques</strong>. Nous vous offrons un service rapide de remplacement des plaquettes, avec des pièces de haute qualité adaptées à votre véhicule."
                )
            },
            {
                question: (
                    "Changement des disques de frein"
                ),
                answer: (
                    "Si vos disques de frein sont <strong>fissurés, trop usés ou déformés</strong>, il est essentiel de les remplacer pour <strong>garantir un freinage efficace</strong> et <strong>sécurisé</strong>. Nous vous proposons le remplacement des disques de frein avec des pièces conformes aux spécifications constructeur."
                )
            },
            {
                question: (
                    "Purge et remplacement du liquide de frein"
                ),
                answer: (
                    "Le liquide de frein joue un rôle essentiel dans le bon fonctionnement de votre système de freinage. S'il est <strong>vieux</strong> ou <strong>contaminé</strong>, il peut affecter les <strong>performances de freinage</strong>. Nous procédons à la <strong>purge</strong> et au <strong>remplacement du liquide de frein</strong> pour vous assurer un freinage réactif et sans danger."
                )
            },
            {
                question: (
                    "Réparation des étriers de frein"
                ),
                answer: (
                    "Les <strong>étriers de frein</strong> sont responsables de <strong>presser les plaquettes contre les disques pour ralentir ou arrêter le véhicule</strong>. Si vos étriers sont défectueux, cela peut <strong>affecter votre capacité à freiner correctement</strong>. Nous inspectons et réparons les étriers de frein pour garantir une action de freinage précise et fiable."
                )
            },
        ],
        faq: [
            {
                question: (
                    "<strong>Q:</strong> À quelle fréquence dois-je remplacer mes plaquettes de frein ?"
                ),
                answer: (
                    "<strong>R:</strong> Il est recommandé de remplacer les plaquettes de frein tous les <strong>30 000 à 50 000 kilomètres</strong>, mais cela peut varier en fonction de votre <strong>style de conduite</strong> et des <strong>conditions de la route</strong>."
                )
            },
            {
                question: (
                    "<strong>Q:</strong> Quels sont les signes que mes disques de frein doivent être changés ?"
                ),
                answer: (
                    "<strong>R:</strong> Si vous entendez un <strong>grincement métallique</strong> ou ressentez des <strong>vibrations lors du freinage</strong>, il est possible que vos disques de frein soient <strong>usés</strong> ou <strong>endommagés</strong> et qu'ils doivent être remplacés."
                )
            },
            {
                question: (
                    "<strong>Q:</strong> Pourquoi ma pédale de frein est-elle molle ?"
                ),
                answer: (
                    "<strong>R:</strong> <strong>Une pédale de frein molle</strong> peut indiquer un <strong>problème de liquide de frein</strong> ou un <strong>défaut dans le système hydraulique</strong>. Nous vous recommandons de faire vérifier vos freins immédiatement."
                )
            },
        ],
        subtitle4: "Prenez rendez-vous pour un contrôle de vos freins",
        description2:"Ne prenez pas de risque avec votre sécurité. <strong>Si vous avez le moindre doute sur l'état de vos freins</strong>, prenez rendez-vous dès aujourd'hui pour un contrôle complet au Garage Ferrand.",
        subtitle5: "Demandez un devis pour le remplacement des pièces",
        icon: "/images/freinage.webp",
        image1: "/images/mecanicien-remplacement-disque-plaquette.webp",
        image2: "/images/remplacement-disque-plaquette.webp",
        schema: {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Freinage",
            "provider": {
              "@type": "AutomotiveBusiness",
              "name": "Garage Ferrand"
            },
            "description": "Service de freinage.",
            "areaServed": {
              "@type": "Place",
              "name": "Le Bailleul"
            }
        }
    },
    // Service 6 -> Réglage Géométrie
    {
        id: 6,
        slug: "reglage-geometrie",
        meta: "Prolongez la durée de vie de vos pneus et améliorez votre tenue de route avec notre service de réglage de géométrie. Rendez-vous au Garage Ferrand pour un contrôle expert.",
        title: "Réglage géométrie",
        subtitle1: "Optimisation de l'alignement de vos roues",
        description: "Le réglage de la géométrie des roues, aussi appelé alignement des roues, est une opération essentielle pour <strong>assurer une conduite sûre, confortable et économique</strong>. Un mauvais alignement peut entraîner une <strong>usure prématurée des pneus</strong>, des <strong>problèmes de tenue de route</strong>, et une <strong>surconsommation de carburant</strong>.",
        subtitle2: "Quand faut-il effectuer un réglage de géométrie ?",
        points: [
            "<strong>Après un choc ou un accident :</strong> Si vous avez heurté un trottoir, un nid-de-poule ou été impliqué dans un accident, il est possible que l'alignement des roues ait été affecté. Un contrôle de la géométrie est alors nécessaire.",
            "<strong>Usure inégale des pneus :</strong> Si vous remarquez que vos pneus s'usent plus rapidement d'un côté ou de manière irrégulière, cela peut être le signe d'un problème de géométrie.",
            "<strong>Tirage du véhicule :</strong> Si votre véhicule tend à tirer d'un côté lorsque vous conduisez, cela peut être dû à un mauvais alignement des roues.",
        ],
        subtitle3: "Notre service de réglage de géométrie comporte :",
        accordions: [
            {
                question: (
                    "Diagnostic complet de la géométrie des roues"
                ),
                answer: (
                    "Nous effectuons un diagnostic précis à l'aide d'équipements de mesure modernes pour vérifier l'alignement de vos roues. Nous <strong>analysons les principaux angles</strong> de la géométrie, tels que le <strong>carrossage</strong>, la <strong>chasse</strong> et le <strong>parallélisme</strong>, pour identifier les ajustements nécessaires."
                )
            },
            {
                question: (
                    "Réglage de l'alignement des roues"
                ),
                answer: (
                    "<strong>Si des écarts sont détectés</strong> lors du diagnostic, nous procédons à un réglage complet de la géométrie pour rétablir les angles corrects. Cela inclut l'ajustement des <strong>angles de parallélisme et de carrossage</strong> pour assurer un alignement optimal des roues."
                )
            },
            {
                question: (
                    "Vérification de la suspension"
                ),
                answer: (
                    "<strong>Une mauvaise suspension peut affecter l'alignement des roues</strong> et provoquer une <strong>usure irrégulière des pneus</strong>. Nous vérifions l’état des <strong>amortisseurs</strong> et des <strong>ressorts</strong> pour s'assurer que la géométrie de votre véhicule reste stable dans le temps."
                )
            },
        ],
        faq: [
            {
                question: (
                    "<strong>Q:</strong> À quelle fréquence dois-je faire régler la géométrie de mes roues ?"
                ),
                answer: (
                    "<strong>R:</strong> Il est conseillé de faire vérifier et ajuster la géométrie de vos roues <strong>environ une fois par an</strong> ou <strong>après avoir heurté un obstacle comme un trottoir ou un nid-de-poule</strong>."
                )
            },
            {
                question: (
                    "<strong>Q:</strong> Quels sont les signes d'un problème de géométrie ?"
                ),
                answer: (
                    "<strong>R:</strong> Les signes courants incluent <strong>une usure irrégulière des pneus, un véhicule qui tire d'un côté</strong>, et <strong>des difficultés à maintenir une trajectoire droite</strong>."
                )
            },
            {
                question: (
                    "<strong>Q:</strong> Quelle est la différence entre un réglage de géométrie et un équilibrage des roues ?"
                ),
                answer: (
                    "<strong>R:</strong> Le <strong>réglage de géométrie</strong> concerne <strong>l'alignement des roues</strong> pour assurer qu'elles soient parallèles entre elles et perpendiculaires au sol, tandis que <strong>l'équilibrage des roues</strong> vise à <strong>répartir uniformément les masses autour des roues</strong> pour éviter les vibrations."
                )
            },
        ],
        subtitle4: "Prenez rendez-vous pour un réglage de géométrie",
        description2:"Si vous remarquez des signes de désalignement ou souhaitez simplement optimiser la tenue de route de votre véhicule, contactez dès aujourd'hui pour un diagnostic complet et un réglage de géométrie précis.",
        subtitle5: "Demandez un devis pour un réglage de géométrie",
        icon: "/images/reglage-geometrie.webp",
        image1: "/images/controle-reglage-geometrie.webp",
        image2: "/images/mécanicien-controle-reglage-geometrie.webp",
        schema: {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Réglage géométrie",
            "provider": {
              "@type": "AutomotiveBusiness",
              "name": "Garage Ferrand"
            },
            "description": "Service de réglage géométrie.",
            "areaServed": {
              "@type": "Place",
              "name": "Le Bailleul"
            }
        }
    },
    // Service 7 -> Distribution
    {
        id: 7,
        slug: "distribution",
        meta: "Évitez la casse moteur en remplaçant votre courroie de distribution à temps. Confiez votre véhicule aux experts du Garage Ferrand pour une intervention rapide et efficace.",
        title: "Distribution",
        subtitle1: "Remplacement et Entretien de la Courroie de Distribution",
        description: "<strong>La courroie de distribution est l'un des composants les plus importants de votre moteur</strong>. Elle <strong>synchronise les mouvements des soupapes et des pistons</strong>, garantissant ainsi le bon fonctionnement du moteur. Une courroie de distribution <strong>usée</strong> ou <strong>défaillante</strong> peut provoquer de graves dommages à votre moteur, nécessitant des <strong>réparations coûteuses</strong>.",
        subtitle2: "Quand remplacer la courroie de distribution ?",
        points: [
            "<strong>Kilométrage recommandé :</strong> Il est généralement conseillé de remplacer la courroie de distribution tous les 100 000 à 150 000 km, selon les recommandations du fabricant de votre véhicule.",
            "<strong>Durée en années :</strong> Même si le kilométrage recommandé n'est pas atteint, la courroie doit être remplacée tous les 5 à 7 ans, car elle s'use avec le temps, même si elle n'est pas beaucoup sollicitée.",
            "<strong>Signes d'usure :</strong> Si vous entendez des bruits de claquement ou de grincement provenant du moteur, cela pourrait indiquer une usure de la courroie ou des galets. Un contrôle immédiat est recommandé pour éviter tout dommage.",
        ],
        subtitle3: "Notre service de distribution comporte :",
        accordions: [
            {
                question: (
                    "Remplacement de la courroie de distribution"
                ),
                answer: (
                    "Si votre <strong>courroie est usée</strong> ou a <strong>atteint son kilométrage limite</strong>, nous procédons au remplacement complet de la courroie, conformément aux spécifications du fabricant de votre véhicule. Nous utilisons des pièces de haute qualité pour assurer la longévité de votre nouveau kit de distribution."
                )
            },
            {
                question: (
                    "Changement des galets tendeurs et de la pompe à eau"
                ),
                answer: (
                    "Lors du remplacement de la courroie de distribution, il est souvent <strong>recommandé de changer également les galets tendeurs et la pompe à eau</strong>, car ils <strong>s'usent en même temps que la courroie</strong>. Cela garantit un fonctionnement optimal du système de distribution et <strong>évite des réparations supplémentaires à court terme</strong>."
                )
            },
            {
                question: (
                    "Réglage et alignement du système de distribution"
                ),
                answer: (
                    "<strong>Après le remplacement de la courroie</strong>, nos techniciens effectuent un réglage précis du système de distribution pour s'assurer que tout fonctionne parfaitement. Cela inclut l'<strong>alignement des pièces</strong> et la <strong>vérification de la tension</strong> de la nouvelle courroie."
                )
            },
        ],
        faq: [
            {
                question: (
                    "<strong>Q:</strong> À quel kilométrage dois-je remplacer ma courroie de distribution ?"
                ),
                answer: (
                    "<strong>R:</strong> En général, la courroie de distribution doit être remplacée tous les <strong>100 000 à 150 000 km</strong>. Il est également recommandé de la remplacer <strong>tous les 5 à 7 ans</strong>, même si le kilométrage n'est pas atteint, car le matériau de la courroie s'use avec le temps."
                )
            },
            {
                question: (
                    "<strong>Q:</strong> Quels sont les signes d'une courroie de distribution usée ?"
                ),
                answer: (
                    "<strong>R:</strong> Les signes d'usure peuvent inclure des <strong>bruits de grincement ou de claquement provenant du moteur</strong>, des <strong>difficultés à démarrer</strong>, ou un <strong>moteur qui cale</strong>. Si vous remarquez l'un de ces symptômes, il est temps de faire inspecter la courroie de distribution.."
                )
            },
            {
                question: (
                    "<strong>Q:</strong> Dois-je remplacer la pompe à eau en même temps que la courroie de distribution ?"
                ),
                answer: (
                    "<strong>R:</strong> Oui, il est généralement recommandé de remplacer la pompe à eau en même temps que la courroie de distribution, car <strong>ces composants s'usent ensemble</strong>. Cela permet <strong>d'éviter des réparations supplémentaires à court terme</strong> et garantit un fonctionnement optimal du moteur."
                )
            },
        ],
        subtitle4: "Prenez rendez-vous pour une inspection de votre courroie de distribution",
        description2:"Ne prenez pas de risque avec votre moteur. Si vous avez atteint le kilométrage recommandé ou si vous soupçonnez une usure de la courroie de distribution, contactez-nous dès aujourd'hui.",
        subtitle5: "Demandez un devis pour changer votre courroie de distribution",
        icon: "/images/distribution.webp",
        image1: "/images/mecanicien-remplacement-courroie-distribution.webp",
        image2: "/images/remplacement-courroie-distribution.webp",
        schema: {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Distribution",
            "provider": {
              "@type": "AutomotiveBusiness",
              "name": "Garage Ferrand"
            },
            "description": "Service de distribution.",
            "areaServed": {
              "@type": "Place",
              "name": "Le Bailleul"
            }
        }
    },
    // Service 8 -> Embrayage
    {
        id: 8,
        slug: "embrayage",
        meta: "Votre embrayage patine ou est difficile à passer ? Faites contrôler et remplacer votre embrayage au Garage Ferrand pour une conduite fluide et sécurisée.",
        title: "Embrayage",
        subtitle1: "Remplacement de votre embrayage",
        description: "Le système d'embrayage est <strong>essentiel pour transmettre la puissance du moteur aux roues de votre véhicule</strong>. Un embrayage <strong>usé</strong> ou <strong>défectueux</strong> peut <strong>entraîner des difficultés de conduite, réduire les performances</strong> et, à terme, <strong>endommager d'autres composants</strong> de votre véhicule.",
        subtitle2: "Quand remplacer votre embrayage ?",
        points: [
            "<strong>Kilométrage élevé :</strong> En général, un embrayage doit être remplacé tous les 100 000 à 150 000 km, en fonction de votre style de conduite et des conditions de la route.",
            "<strong>Conduite urbaine fréquente :</strong> Les conducteurs qui passent souvent les vitesses en ville peuvent user leur embrayage plus rapidement. Une vérification régulière est donc recommandée.",
            "<strong>Signes d'usure :</strong> Si vous remarquez un patinage, des bruits anormaux ou des difficultés à passer les vitesses, il est temps de faire inspecter votre embrayage.",
        ],
        subtitle3: "Notre service d'embrayage comporte :",
        accordions: [
            {
                question: (
                    "Diagnostic complet du système d'embrayage"
                ),
                answer: (
                    "Nous effectuons un diagnostic complet de votre embrayage pour <strong>identifier les signes d'usure</strong> et déterminer s'il doit être remplacé. Nous vérifions les composants clés, tels que les <strong>disques</strong>, le <strong>câble d'embrayage</strong>, la <strong>butée</strong>, et le <strong>volant moteur</strong>."
                )
            },
            {
                question: (
                    "Remplacement du kit d'embrayage complet"
                ),
                answer: (
                    "Si votre embrayage est fortement endommagé ou si plusieurs composants sont usés, nous vous proposons un remplacement complet du kit d'embrayage (disques, butée, câble) pour vous assurer une réparation durable."
                )
            },
            {
                question: (
                    "Réglage ou remplacement du câble d'embrayage"
                ),
                answer: (
                    "<strong>Si votre pédale d'embrayage devient molle ou difficile à enfoncer</strong>, il est possible que <strong>le câble d'embrayage soit détendu ou usé</strong>. Nous procédons à un réglage ou à un remplacement du câble pour vous garantir un fonctionnement fluide."
                )
            },
            {
                question: (
                    "Remplacement du volant moteur"
                ),
                answer: (
                    "<strong>Le volant moteur joue un rôle crucial dans le bon fonctionnement de votre embrayage</strong>. Si celui-ci est usé ou endommagé, il peut <strong>provoquer des vibrations et affecter la transmission de puissance</strong>. Nous procédons au remplacement du volant moteur si nécessaire."
                )
            },
        ],
        faq: [
            {
                question: (
                    "<strong>Q:</strong> Combien de temps dure un embrayage ?"
                ),
                answer: (
                    "<strong>R:</strong> La durée de vie moyenne d'un embrayage est de <strong>100 000 à 150 000 kilomètres</strong>. Cependant, <strong>cela dépend de votre style de conduite</strong> et des <strong>conditions routières</strong>. Une conduite en ville ou des démarrages fréquents peuvent accélérer l'usure."
                )
            },
            {
                question: (
                    "<strong>Q:</strong> Quels sont les signes d'un embrayage usé ?"
                ),
                answer: (
                    "<strong>R:</strong> Les signes d'un embrayage usé incluent le <strong>patinage</strong>, des <strong>bruits anormaux</strong> lors des changements de vitesse, une <strong>pédale d'embrayage molle ou dure</strong>, et des <strong>difficultés à passer les vitesses</strong>."
                )
            },
            {
                question: (
                    "<strong>Q:</strong> Comment entretenir son embrayage pour prolonger sa durée de vie ?"
                ),
                answer: (
                    "<strong>R:</strong> Pour prolonger la durée de vie de votre embrayage, il est conseillé d'<strong>adopter une conduite souple en évitant les démarrages brusques et les à-coups</strong>. Il est également recommandé d'<strong>éviter de garder le pied sur la pédale d'embrayage</strong> lorsque ce n'est pas nécessaire, car cela peut accélérer l'usure."
                )
            },
        ],
        subtitle4: "Prenez rendez-vous pour un diagnostic de votre embrayage",
        description2:"Si vous remarquez des signes d'usure sur votre embrayage, n'attendez pas que la situation empire. Contactez-nous dès aujourd'hui.",
        icon: "/images/embrayage.webp",
        image1: "/images/remplacement-embrayage.webp",
        image2: "/images/mecanicien-remplacement-embrayage.webp",
        schema: {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Embrayage",
            "provider": {
              "@type": "AutomotiveBusiness",
              "name": "Garage Ferrand"
            },
            "description": "Service d'embrayage.",
            "areaServed": {
              "@type": "Place",
              "name": "Le Bailleul"
            }
        }
    },
    // Service 9 -> Pare-Brise
    {
        id: 9,
        slug: "pare-brise",
        meta: "Un impact ou une fissure sur votre pare-brise ? Réparation ou remplacement rapide au Garage Ferrand pour une visibilité et une sécurité optimales.",
        title: "Pare-Brise",
        subtitle1: "Remplacement de Pare-brise chez Bosch Car Service",
        description: "Chez Garage Ferrand, nous vous garantissons un <strong>remplacement de pare-brise rapide et efficace</strong> pour assurer votre sécurité et maintenir une visibilité optimale sur la route. <strong>Que votre pare-brise soit fissuré, brisé ou trop endommagé pour être réparé</strong>, nos mécaniciens se chargent de le <strong>retirer et d'installer un nouveau pare-brise</strong> en respectant les normes de qualité les plus strictes.",
        subtitle2: "Pourquoi remplacer votre pare-brise ?",
        points: [
            "<strong>Sécurité accrue :</strong> Un pare-brise endommagé peut nuire à la rigidité de la structure de votre véhicule, augmentant les risques en cas d'accident.",
            "<strong>Visibilité optimale :</strong> Une fissure ou un éclat peut gêner votre champ de vision et créer des reflets gênants, surtout sous la lumière du soleil ou de nuit.",
            "<strong>Conformité légale :</strong> Rouler avec un pare-brise endommagé peut entraîner une contravention lors du contrôle technique et compromettre la validité de votre assurance.",
        ],
        subtitle3: "Notre service de remplacement de pare-brise comporte :",
        accordions: [
            {
                question: (
                    "Démontage et installation d'un nouveau pare-brise"
                ),
                answer: (
                    "Nous nous occupons du <strong>démontage complet de votre ancien pare-brise et de l'installation d'un neuf</strong>, adapté à votre véhicule et conforme aux normes constructeurs."
                )
            },
            {
                question: (
                    "Calibrage des caméras ADAS"
                ),
                answer: (
                    "Si votre véhicule est équipé de systèmes d'assistance à la conduite (caméras ADAS), <strong>nous collaborons avec des experts partenaires pour assurer le calibrage des caméras</strong> une fois le pare-brise installé, garantissant un fonctionnement optimal de vos aides à la conduite."
                )
            },
            {
                question: (
                    "Qualité et rapidité"
                ),
                answer: (
                    "Nos techniciens qualifiés vous garantissent un remplacement rapide, tout en utilisant des pare-brises et des matériaux de la meilleure qualité pour assurer votre sécurité à long terme."
                )
            },
        ],
        faq: [
            {
                question: (
                    "<strong>Q:</strong> Mon pare-brise est fissuré, dois-je le réparer ou le remplacer ?"
                ),
                answer: (
                    "<strong>R:</strong> Si la fissure est <strong>plus grande qu'une pièce de deux euros ou se trouve dans le champ de vision du conducteur</strong>, il est souvent recommandé de remplacer le pare-brise. Pour les dommages mineurs, une réparation peut suffire, mais un contrôle professionnel est nécessaire pour évaluer la meilleure solution."
                )
            },
            {
                question: (
                    "<strong>Q:</strong> Combien de temps dure le remplacement d'un pare-brise ?"
                ),
                answer: (
                    "<strong>R:</strong> Le remplacement d'un pare-brise prend généralement <strong>entre 2 et 3 heures</strong>, selon le modèle de votre véhicule. Cela inclut le <strong>démontage de l'ancien pare-brise</strong>, la <strong>pose du nouveau</strong>, ainsi que le <strong>temps de séchage nécessaire</strong> avant de pouvoir conduire à nouveau."
                )
            },
            {
                question: (
                    "<strong>Q:</strong> Dois-je faire calibrer les caméras après le remplacement de mon pare-brise ?"
                ),
                answer: (
                    "<strong>R:</strong> Oui, <strong>si votre véhicule est équipé de systèmes d'assistance à la conduite (ADAS), un recalibrage des caméras est nécessaire</strong> après le remplacement du pare-brise pour garantir que les systèmes de sécurité fonctionnent correctement. Nous travaillons avec des experts pour effectuer ce calibrage."
                )
            },
        ],
        subtitle4: "Prenez rendez-vous pour le remplacement de votre pare-brise",
        description2:"Assurez-vous que votre pare-brise est en parfait état pour garantir votre sécurité sur la route. Si vous avez une fissure ou un éclat, n'attendez pas que le problème s'aggrave. Contactez-nous dès aujourd'hui.",
        icon: "/images/pare-brise.webp",
        image1: "/images/mecanicien-enleve-pare-brise.webp",
        image2: "/images/pose-pare-brise.webp",
        schema: {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Pare-brise",
            "provider": {
              "@type": "AutomotiveBusiness",
              "name": "Garage Ferrand"
            },
            "description": "Service de pare-brise.",
            "areaServed": {
              "@type": "Place",
              "name": "Le Bailleul"
            }
        }
    },
    // Service 10 -> Eclairage
    {
        id: 10,
        slug: "eclairage",
        meta: "Un bon éclairage est essentiel pour une conduite sécurisée. Vérifiez et remplacez vos phares au Garage Ferrand pour une visibilité optimale de jour comme de nuit.",
        title: "Éclairage",
        subtitle1: "Service de Contrôle et Entretien des Feux chez Garage Ferrand",
        description: "Les feux de votre véhicule jouent un rôle crucial dans votre sécurité sur la route. Que ce soit pour bien voir ou pour être vu des autres conducteurs, <strong>un bon fonctionnement de vos feux est essentiel</strong>. Chez Garage Ferrand, nous offrons une gamme complète de services pour garantir que vos phares, feux arrière, clignotants et autres systèmes d'éclairage fonctionnent parfaitement.",
        subtitle2: "Pourquoi remplacer votre pare-brise ?",
        points: [],
        subtitle3: "Notre service d'éclairage comporte :",
        accordions: [
            {
                question: (
                    "Contrôle des Feux"
                ),
                answer: (
                    "Le contrôle régulier de l'état de vos feux est indispensable pour assurer votre sécurité et celle des autres usagers de la route. <strong>Nous vérifions la bonne visibilité de vos phares ainsi que leur alignement</strong>, afin d'éviter tout éblouissement des conducteurs venant en sens inverse. De plus, <strong>nous nous assurons que vos clignotants, feux de recul et feux stop fonctionnent parfaitement</strong>."
                )
            },
            {
                question: (
                    "Changement d'Ampoule ou LED"
                ),
                answer: (
                    "Que vous ayez besoin de <strong>remplacer une simple ampoule halogène</strong> ou d'<strong>installer des feux LED plus performants</strong>, nos techniciens se chargent de tout. Nous utilisons des produits de qualité pour garantir une longue durée de vie et une luminosité optimale. Vous pouvez choisir entre différents types d'ampoules <strong>(halogène, xénon, LED)</strong> en fonction de vos besoins et de votre budget."
                )
            },
            {
                question: (
                    "Lustrage des Phares"
                ),
                answer: (
                    "<strong>Avec le temps, les phares peuvent devenir opaques ou jaunir</strong>, ce qui réduit considérablement leur efficacité. Chez Garage Ferrand, nous proposons un service de <strong>lustrage des phares pour restaurer leur clarté et leur brillance</strong>. Grâce à notre technique de polissage, vos phares retrouveront leur état d'origine, améliorant ainsi votre visibilité nocturne et augmentant la durée de vie de vos feux."
                )
            },
        ],
        faq: [
            {
                question: (
                    "<strong>Q:</strong> À quelle fréquence dois-je contrôler l'état de mes feux ?"
                ),
                answer: (
                    "<strong>R:</strong> Il est recommandé de vérifier l'état de vos feux tous les 6 mois, ou avant un long trajet. Si vous remarquez que vos feux sont moins lumineux, jaunissent, ou que leur alignement semble incorrect, un contrôle chez un professionnel est conseillé."
                )
            },
            {
                question: (
                    "<strong>Q:</strong> Comment savoir si mes phares ont besoin d'un lustrage ?"
                ),
                answer: (
                    "<strong>R:</strong> Si vos phares semblent opaques, jaunis ou que leur éclairage est réduit, il est probable qu'ils aient besoin d'un lustrage. Ce traitement permet de restaurer la clarté des phares et d'améliorer leur performance lumineuse."
                )
            },
            {
                question: (
                    "<strong>Q:</strong> Quelle est la différence entre une ampoule halogène, xénon et LED ?"
                ),
                answer: (
                    "<strong>R:</strong> Les ampoules halogènes sont les plus courantes, moins chères mais ont une durée de vie plus courte. Les ampoules xénon offrent une lumière plus blanche et plus intense, mais sont plus coûteuses. Les LED sont les plus durables, très lumineuses, et consomment moins d'énergie."
                )
            },
        ],
        subtitle4: "Prenez rendez-vous pour un remplacement de vos feux",
        description2:"Que ce soit pour un contrôle de l'éclairage, un lustrage des phares ou un changement d'ampoule/LED, n'attendez pas que des problèmes surviennent. Contactez-nous dès aujourd'hui.",
        subtitle5: "Demandez un devis pour un remplacement de vos feux",
        icon: "/images/eclairage.webp",
        image1: "/images/mecanicien-entretien-lustrage.webp",
        image2: "/images/lustrage.webp",
        schema: {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Eclairage",
            "provider": {
              "@type": "AutomotiveBusiness",
              "name": "Garage Ferrand"
            },
            "description": "Service d'éclairage.",
            "areaServed": {
              "@type": "Place",
              "name": "Le Bailleul"
            }
        }
    },
];
  
module.exports = services;