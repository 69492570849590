import { useEffect } from "react";
import services from "../../data/services";

export default function Services() {
    useEffect(() => {
        document.title = `Nos services - Garage Ferrand`;

        let metaDescription = document.querySelector("meta[name='description']");

        if (metaDescription) {
            metaDescription.setAttribute("content", "Découvrez tous nos services d'entretien et de réparation automobile : vidange, freinage, climatisation, pneumatique et bien plus encore. Prenez rendez-vous dès aujourd'hui au Garage Ferrand à Le Bailleul.");
        } else {
            // Sinon, on la crée et on l'ajoute au <head>
            metaDescription = document.createElement("meta");
            metaDescription.name = "description";
            metaDescription.content = "Découvrez tous nos services d'entretien et de réparation automobile : vidange, freinage, climatisation, pneumatique et bien plus encore. Prenez rendez-vous dès aujourd'hui au Garage Ferrand à Le Bailleul.";
            document.head.appendChild(metaDescription);
        }
    }, []);

    return (
        <main className="pt-36 lg:pt-10 pb-10 md:container md:w-[600px] lg:w-[900px] 3xl:w-[1100px] mx-10 md:mx-auto">
            <section>
                <h1 className="text-center text-2xl md:text-3xl 2xl:text-4xl font-medium text-primary">Nos services</h1>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 lg:gap-6 mt-8">
                    {services.map((service) => (
                        <a key={service.id} href={`/services/${service.slug}`} className="flex lg:flex-col items-center justify-start lg:justify-center pl-10 lg:pl-0 gap-4 bg-white py-4 lg:py-0 lg:px-0 lg:p-10 lg:h-52 rounded-lg shadow-md text-center cursor-pointer lg:hover:scale-105 lg:hover:shadow-xl transition transform duration-300">
                            <img src={service.icon} alt={service.title} className=" lg:mb-4 w-10 h-10 lg:w-16 lg:h-16 "/>
                            <p className="text-semibold">{service.title}</p>
                        </a>
                    ))}
                </div>
            </section>
        </main>
    );
}