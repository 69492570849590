const reviews = [
  {
      id:1,
      "@context": "https://schema.org",
      "@type": "Review",
      author: {
          "@type": "Person",
          name: "Nicolas Nico"
      },
      reviewRating: {
          "@type": "Rating",
          ratingValue: "5",
          bestRating: "5"
      },
      reviewBody: "Garage hors du commun ! Un injecteur m'a lâché sur l'autoroute, j'ai pu tant bien que mal me rendre dans ce garage où j'ai été pleinement pris en charge :  diagnostic, commande de l'injecteur car non dispo en stock, récupération dans la foulée dans 2 établissements éloignés de près de 15 km du garage puis installation, j'ai pu directement reprendre la route. On nous a également déposé à un endroit où j'ai pu prendre de quoi manger pour mes enfants et moi même. Bref, je recommande vivement cet établissement pour ces  grandes qualités techniques et humaines !",
      "itemReviewed": {
          "@type": "AutomotiveBusiness",
          "name": "Garage Ferrand"
      }
  },
  {
      id:2,
      "@context": "https://schema.org",
      "@type": "Review",
      author: {
          "@type": "Person",
          name: "Antonina Lemoine"
      },
      reviewRating: {
          "@type": "Rating",
          ratingValue: "5",
          bestRating: "5"
      },
      reviewBody: "Un vendredi soir 18h , une banale crevaison sur l'autoroute , en 30 mins nous étions remorqués , en 30 mins l'intervention était effectuée et le dossier d'assurance géré. Les gérants sont en plus très agréables et très accueillants , je vous recommande vivement ce garage",
      "itemReviewed": {
          "@type": "AutomotiveBusiness",
          "name": "Garage Ferrand"
      }
  },
  {
      id:3,
      "@context": "https://schema.org",
      "@type": "Review",
      author: {
        "@type": "Person",
        name: "Sébastien Henaux"
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5"
      },
      reviewBody: "Crevaison sur autoroute, je sors 3kms après et voulant me rendre à norauto à 14 kms je tombe sur ce garage. Une personne réactive et en moins de 15 mns je reprends  la route. Merci beaucoup",
      "itemReviewed": {
        "@type": "AutomotiveBusiness",
        "name": "Garage Ferrand"
      }
  },
  {
      id:4,
      "@context": "https://schema.org",
      "@type": "Review",
      author: {
        "@type": "Person",
        name: "Inès Tabaï"
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5"
      },
      reviewBody: "Merci à Sandrine la gérante de ce garage  pour son accueil et sa gentillesse. Nous avons pu profiter d'un bon repas grâce à elle. Merci encore",
      "itemReviewed": {
        "@type": "AutomotiveBusiness",
        "name": "Garage Ferrand"
      }
  },
  {
      id:5,
      "@context": "https://schema.org",
      "@type": "Review",
      author: {
        "@type": "Person",
        name: "Sébastien LEBLANC"
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5"
      },
      reviewBody: "Visite suite affichage panne sur tableau de bord. Très bon accueil, lecture code erreur sur valise, le garagiste qui devait être le gérant m'a rassuré. Très bon contact qui inspire confiance. Je recommande et j'y retournerai car j'habite vraiment pas loin.",
      "itemReviewed": {
        "@type": "AutomotiveBusiness",
        "name": "Garage Ferrand"
      }
  },
  {
      id:6,
      "@context": "https://schema.org",
      "@type": "Review",
      author: {
        "@type": "Person",
        name: "john vitu (DEASTEK)"
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5"
      },
      reviewBody: "depannage sur auto-route et très arrangeant, d'ailleurs si vous souhaitez un site web pour votre garage deastek.fr je serais ravi de vous proposer un site web en location",
      "itemReviewed": {
        "@type": "AutomotiveBusiness",
        "name": "Garage Ferrand"
      }
  },
  {
      id:7,
      "@context": "https://schema.org",
      "@type": "Review",
      author: {
        "@type": "Person",
        name: "Émeric GAUTIER"
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5"
      },
      reviewBody: "Je suis satisfait de la prestation du garage de Mr Ferrand. Bon courage à vous et merci!",
      "itemReviewed": {
        "@type": "AutomotiveBusiness",
        "name": "Garage Ferrand"
      }
  },
  {
      id:8,
      "@context": "https://schema.org",
      "@type": "Review",
      author: {
        "@type": "Person",
        name: "Kevin GUILLON"
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5"
      },
      reviewBody: "Nous a dépanner assez rapidement ! Agréable et nous on passer une voiture de dépannage !",
      "itemReviewed": {
        "@type": "AutomotiveBusiness",
        "name": "Garage Ferrand"
      }
  },
  {
      id:9,
      "@context": "https://schema.org",
      "@type": "Review",
      author: {
        "@type": "Person",
        name: "Mickaël Catherine"
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5"
      },
      reviewBody: "Bon garage et location de voitures très pratiques rares de nos jours",
      "itemReviewed": {
        "@type": "AutomotiveBusiness",
        "name": "Garage Ferrand"
      }
  },
  {
      id:10,
      "@context": "https://schema.org",
      "@type": "Review",
      author: {
        "@type": "Person",
        name: "jim"
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "4",
        bestRating: "5"
      },
      reviewBody: "Garagiste sérieux à votre écoute",
      "itemReviewed": {
        "@type": "AutomotiveBusiness",
        "name": "Garage Ferrand"
      }
  }
]

export default reviews;