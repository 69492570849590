import { useEffect } from "react";

function MentionsLegales () {
    // TITLE ET META DESCRIPTION
    useEffect(() => {
        document.title = "Mentions Légales - Garage Ferrand";

        let metaDescription = document.querySelector("meta[name='description']");
        if (!metaDescription) {
            metaDescription = document.createElement("meta");
            metaDescription.name = "description";
            document.head.appendChild(metaDescription);
        }
        metaDescription.setAttribute("content", "Consultez les mentions légales du site Garage Ferrand : éditeur, hébergeur, accès au site et protection des données personnelles.");
    }, []);
    return (
        <div className='pt-16 pb-10 flex flex-col justify-center gap-10 mx-10 lg:mx-56 3xl:mx-96 text-center'>
            <h1 className='text-center pb-4 text-xl md:text-2xl text-primary font-semibold'>Mentions légales du site</h1>

            <p>Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l'économie numérique, dite L.C.E.N., il est porté à la connaissance des utilisateurs et visiteurs</p>

            <h2 className="text-lg text-primary font-medium mt-4">ARTICLE 1 - L'EDITEUR</h2>
            <p>Le site garageferrand.fr est édité par Eva Ferrand, domiciliée 6 impasse des violettes, 
            dont le numéro de téléphone est 06 26 69 55 70, et l'adresse e-mail ferrandevapro@gmail.com </p>
    
            <h2 className="text-lg text-primary font-medium mt-4">ARTICLE 2 - L'HEBERGEUR</h2>
            <p>L'hébergeur du Site est la société OVH, dont le siège social est situé au 2 rue Kellermann - 59100 Roubaix - France,SAS au capital de 10 069 020 € RCS Lille Métropole 424 761 419 00045 Code APE 2620Z N° TVA : FR 22 424 761 419</p>
    
            <h2 className="text-lg text-primary font-medium mt-4">ARTICLE 3 - ACCES AU SITE</h2>
            <p>Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption 
                programmée ou non et pouvant découlant d'une nécessité de maintenance.
                En cas de modification, interruption ou suspension du Site, l'Editeur ne saurait être tenu responsable.
            </p>

            <h2 className="text-lg text-primary font-medium mt-4">ARTICLE 4 - COLLECTE DES DONNEES</h2>
            <p>Le Site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect 
                de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers 
                et aux libertés. 
                En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit 
                d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur 
                exerce ce droit :
                · par mail à l'adresse email ferrandevapro@gmail.com
                Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou partie du Site, 
                sans autorisation de l'Editeur est prohibée et pourra entraînée des actions et poursuites judiciaires 
                telles que notamment prévues par le Code de la propriété intellectuelle et le Code civil.</p>
        </div>
    )
}

export default MentionsLegales;