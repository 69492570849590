import { useEffect } from "react";
import articles from "../../data/articles";

export default function Actualites() {
  useEffect(() => {
    document.title = `Nos actualités - Garage Ferrand`;

    let metaDescription = document.querySelector("meta[name='description']");

    if (metaDescription) {
        metaDescription.setAttribute("content", "Retrouvez les dernières actualités et conseils auto du Garage Ferrand. Entretien, réparations, sécurité routière : restez informé pour prendre soin de votre véhicule.");
    } else {
        // Sinon, on la crée et on l'ajoute au <head>
        metaDescription = document.createElement("meta");
        metaDescription.name = "description";
        metaDescription.content = "Retrouvez les dernières actualités et conseils auto du Garage Ferrand. Entretien, réparations, sécurité routière : restez informé pour prendre soin de votre véhicule.";
        document.head.appendChild(metaDescription);
    }
  }, []);

  return (
    <main className="pt-36 lg:pt-10 pb-10 md:container md:w-[600px] lg:w-[900px] 3xl:w-[1100px] mx-10 md:mx-auto">
      <section>
        <h1 className="text-center text-2xl md:text-3xl 2xl:text-4xl font-medium text-primary">Nos actualités</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-8">
          {articles.map((article) => (
            <div key={article.id} className="bg-white flex flex-col items-center justify-between rounded-xl overflow-hidden shadow-md lg:hover:scale-105 lg:hover:shadow-xl transition transform duration-300" onClick={() => window.location.href = `/actualites/${article.slug}`}>
              <img
                src={article.image}
                alt={article.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <p className="text-sm text-gray-500">{article.date}</p>
                <h2 className="text-lg font-medium mt-2 text-gray-900">{article.title}</h2>
                <p className="text-gray-600 mt-2 text-sm line-clamp-3">{article.subtitle}</p>
                <a
                  href={`/actualites/${article.slug}`}
                  className="text-primary mt-4 inline-block font-medium"
                >Lire plus</a>
              </div>
            </div>
          ))}
        </div>
      </section>
    </main>
  );
}