import { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Accueil from './screens/Accueil';
import Header from './Header';
import Actualites from "./screens/articles/Actualites";
import Article from "./screens/articles/[slug]";
import Partenaires from "./components/Partenaires";
import Footer from "./Footer";
import Services from "./screens/services/Services";
import ServiceDetail from "./screens/services/[slug]";
import Contact from "./screens/Contact";
import usePageTracking from "./usePageTracking";
import Page404 from "./screens/Page404";
import MentionsLegales from "./screens/MentionsLegales";
import PolitiqueConfidentialite from "./screens/PolitiqueConfidentialite";
import CGU from "./screens/CGU";
import CookieConsent from "./components/CookieConsent";

function App() {
  const location = useLocation();
  usePageTracking(location); 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [headerHeight, setHeaderHeight] = useState(0);

  
  return (
    <div>
      <CookieConsent/>
      <Header setHeaderHeight={setHeaderHeight}/>
      <Routes>
        <Route path="/" element={<Accueil headerHeight={headerHeight}/>}/>
        <Route path="/services" element={<Services/>}/>
        <Route path="/services/:slug" element={<ServiceDetail/>}/>
        <Route path="/actualites" element={<Actualites/>}/>
        <Route path="/actualites/:slug" element={<Article/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/mentions-legales" element={<MentionsLegales/>}/>
        <Route path="/politique-confidentialite" element={<PolitiqueConfidentialite/>}/>
        <Route path="/cgu" element={<CGU/>}/>
        <Route path="*" element={<Page404 />} />
      </Routes>
      <Partenaires/>
      <Footer/>
    </div>
  );
}

export default App;