import { useEffect } from "react";

export default function CGU() {
  useEffect(() => {
    document.title = "Conditions Générales d'Utilisation - Garage Ferrand";

    let metaDescription = document.querySelector("meta[name='description']");
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.setAttribute("content", "Consultez les conditions générales d'utilisation du site Garage Ferrand.");

  }, []);

  return (
    <div className="pt-16 pb-10 flex flex-col justify-center gap-10 mx-10 lg:mx-56 3xl:mx-96 text-center">
      <h1 className="text-center pb-4 text-xl md:text-2xl text-primary font-semibold">Conditions Générales d'Utilisation</h1>

      <h2 className="text-lg text-primary font-medium mt-4">1. Objet</h2>
      <p>Les présentes Conditions Générales d'Utilisation définissent les règles d'accès et d'utilisation du site <strong>Garage Ferrand</strong>.</p>

      <h2 className="text-lg text-primary font-medium mt-4">2. Accès au site</h2>
      <p>Le site est accessible gratuitement depuis tout appareil disposant d'une connexion Internet.</p>

      <h2 className="text-lg text-primary font-medium mt-4">3. Propriété intellectuelle</h2>
      <p>Tout le contenu du site (textes, images, logos, etc.) est protégé par le droit de la propriété intellectuelle. Toute reproduction sans autorisation est interdite.</p>

      <h2 className="text-lg text-primary font-medium mt-4">4. Responsabilité</h2>
      <p>Garage Ferrand ne peut être tenu responsable des erreurs ou indisponibilités du site.</p>

      <h2 className="text-lg text-primary font-medium mt-4">5. Contact</h2>
      <p>Pour toute question, vous pouvez nous contacter à <strong>ferrand87@orange.fr</strong></p>
    </div>
  );
}