import React from 'react';

function MapIframe() {
    return (
      <div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2681.422652465146!2d-0.21418092370661515!3d47.77325197120551!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48083d84ac5a05cd%3A0x2a2c26dfc0bb62cf!2s30%20Rue%20du%20Portugal%2C%2072200%20Le%20Bailleul!5e0!3m2!1sfr!2sfr!4v1726740610241!5m2!1sfr!2sfr" width="580" height="300" allowFullScreen="" referrerPolicy="no-referrer-when-downgrade" className='rounded-lg w-full'></iframe>
      </div>
    );
  }
  
  export default MapIframe;