import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

function Partenaires () {
    return (
        <div data-cookieconsent="ignore" className='bg-white pt-4'>
            <Swiper 
                slidesPerView={2} 
                spaceBetween={30} 
                centeredSlides={true}  
                loop={true} 
                modules={[Autoplay]} 
                className="mySwiper h-32"
                autoplay={{ delay: 1000, disableOnInteraction: false, }}
                breakpoints={{
                    640: {
                        slidesPerView: 4,
                    },
                    1000: {
                        slidesPerView: 6,
                    }
                }}
            >
                <SwiperSlide>
                    <div className='w-32 h-32 flex items-center justify-center'>
                        <img src="/images/logo-arc-europe.webp" alt='Logo Arc Europe' width='130px' height='130px'/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-32 h-32 flex items-center justify-center'>
                        <img src="/images/logo-ACTA.webp" alt='Logo Acta' width='130px' height='130px'/>
                    </div>
                </SwiperSlide> 
                <SwiperSlide>
                    <div className='w-32 h-32 flex items-center justify-center'>
                        <img src="/images/logo-mapfre.webp" alt='Logo Mapfre' width='110px' height='110px'/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-32 h-32 flex items-center justify-center'>
                        <img src="/images/logo-mondial-assistance.webp" alt='Logo Mondial Assistance' width='130px' height='130px'/>
                    </div>
                </SwiperSlide> 
                <SwiperSlide>
                    <div className='w-32 h-32 flex items-center justify-center'>
                        <img src="/images/logo-opteven.webp" alt='Logo Opteven' width='130px' height='130px'/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-32 h-32 flex items-center justify-center'>
                        <img src="/images/logo-europ-assistance.webp" alt='Logo Europ Assistance' width='130px' height='130px'/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-32 h-32 flex items-center justify-center'>
                        <img src="/images/logo-save-assistance.webp" alt='Logo Save Assistance' width='110px' height='110px'/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-32 h-32 flex items-center justify-center'>
                        <img src="/images/logo-bnp-paribas.webp" alt='Logo BNP Paribas' width='130px' height='130px'/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-32 h-32 flex items-center justify-center'>
                        <img src="/images/logo-oney.webp" alt='Logo Oney' width='130px' height='130px'/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-32 h-32 flex items-center justify-center'>
                        <img src="/images/logo-axa.webp" alt='Logo Axa' width='100px' height='100px'/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-32 h-32 flex items-center justify-center'>
                        <img src="/images/logo-avis-budget-group.webp" alt='Logo Avis Budget Group' width='130px' height='130px'/>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    );
}

export default Partenaires;