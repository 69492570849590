import { useParams } from "react-router-dom";
import articles from "../../data/articles";
import { HeadsetIcon } from "hugeicons-react";

export default function Article() {
  const { slug } = useParams();

  // Récupère l'article correspondant
  const article = articles.find((a) => a.slug === slug);

  if (!article) {
    return <p>Article non trouvé.</p>;
  }

  return (
    <main className="md:container md:w-[600px] lg:w-[900px] 3xl:w-[1100px] mx-10 md:mx-auto pt-32 lg:pt-10 flex flex-col items-center">
      <section>
        <div className="flex flex-col items-center justify-center gap-4 mb-10">
          <h1 className="text-center text-2xl md:text-3xl font-medium text-primary">{article.title}</h1>
          <h2 className="text-center">{article.subtitle}</h2>
          <p className="text-gray-600">Publié le {article.date}</p>
          <img src={article.image} alt="Bannière batterie" className="mx-auto h-[300px] rounded-lg shadow-md lg:hover:scale-105 transition transform duration-300" />
        </div>

        <div className="flex flex-col items-center justify-center gap-4 mb-10 bg-white shadow-md p-6 rounded-lg"> 
          <h3 className="text-lg font-medium text-primary text-center">{article.subtitle1}</h3>
          <ul className="list-disc">
            {article.description1.map((point, index) => (
              <li key={index} dangerouslySetInnerHTML={{ __html: point }}></li>
            ))}
          </ul>
        </div>

        {(article.description2 && article.description2.length > 0) && (
          <div className="flex flex-col items-center justify-center gap-4 mb-10 bg-white shadow-md p-6 rounded-lg">
            <h3 className="text-lg font-medium text-primary text-center lg:px-10">{article.subtitle2}</h3>
            <ul>
              {article.description2.map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          </div>
        )}
        
        {(article.description3 && article.description3.length > 0) && (
          <div className="flex flex-col items-center justify-center gap-4 mb-10 bg-white shadow-md p-6 rounded-lg text-center lg:px-10">
            <h3 className="text-lg font-medium text-primary ">{article.subtitle3}</h3>
            <p>{article.description3}</p>
            <ul>
              {article.points.map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          </div>
        )}

        <div className="bg-white px-4 lg:px-10 py-8 rounded-xl shadow-md flex flex-col items-center justify-between text-center gap-4 mb-20">
          <HeadsetIcon className="w-16 h-16 text-primary mx-10" aria-label="Icône Téléphone"/>
          <h2 className="text-base md:text-xl text-primary font-medium lg:mx-10 md:mx-0">Prenez rendez-vous</h2>
          <p className="lg:px-10">Si vous remarquez des problèmes sur votre véhicule ou souhaitez simplement vous assurer que tout est en ordre, n'attendez pas que la situation empire. Contactez-nous dès aujourd'hui pour entretenir votre véhicule.</p>
          <a href="tel:+0243455023" className="bg-primary text-white px-6 py-2 rounded-full shadow-lg hover:bg-primary-dark transition lg:text-center">
            <p>02 43 45 50 23</p>
          </a>
        </div>
      </section>

      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(article.schema) }} />
    </main>
  );
};