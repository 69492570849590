import { useEffect } from "react";

// ICONS
import { IoMail, IoLocationSharp } from "react-icons/io5";
import { PiPhoneCallFill } from "react-icons/pi";

// FILLOUT
import { FilloutStandardEmbed } from "@fillout/react";
import "@fillout/react/style.css";

export default function Contact () {
    useEffect(() => {
        document.title = `Nous contacter - Garage Ferrand`;

        let metaDescription = document.querySelector("meta[name='description']");

        if (metaDescription) {
            metaDescription.setAttribute("content", "Besoin d'un renseignement ou d'un rendez-vous ? Contactez le Garage Ferrand à Le Bailleul par téléphone, email ou via notre formulaire en ligne. Nous sommes à votre service.");
        } else {
            // Sinon, on la crée et on l'ajoute au <head>
            metaDescription = document.createElement("meta");
            metaDescription.name = "description";
            metaDescription.content = "Besoin d'un renseignement ou d'un rendez-vous ? Contactez le Garage Ferrand à Le Bailleul par téléphone, email ou via notre formulaire en ligne. Nous sommes à votre service.";
            document.head.appendChild(metaDescription);
        }
    }, []);

    return (
        <div className='pt-36 lg:pt-10 pb-10 flex flex-col justify-center gap-6 lg:gap-10 md:container md:w-[600px] lg:w-[900px] 3xl:w-[1100px] mx-10 md:mx-auto'>
            <h1 className="text-center text-2xl md:text-3xl 2xl:text-4xl font-medium text-primary">Nous contacter</h1>
            {/* INFORMATIONS CONTACT */}
            <div className='flex flex-row justify-center gap-10 w-full'>
                <a href="https://www.google.fr/maps/place/Garage+Pascal+Ferrand/@47.7733533,-0.2146748,17z/data=!4m6!3m5!1s0x481117e7ef5eb399:0x53afc46dbd157bbd!8m2!3d47.7733497!4d-0.2120999!16s%2Fg%2F1tdq0h_y?entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D" target='_blank' rel="noreferrer" className="bg-white p-4 md:p-10 md:h-32 rounded-full lg:rounded-lg shadow-md text-center flex flex-col items-center justify-center gap-2 cursor-pointer lg:w-1/3">
                    <div>
                        <IoLocationSharp className='w-8 h-8 text-primary' aria-label="Icône Localisation"/>
                    </div>
                    <p className='text-primary hidden md:block'>30 rue du Portugal 72200 Le Bailleul</p>
                </a>
                <a href="tel:0243455023" className="bg-white p-4 md:p-10 md:h-32 rounded-full lg:rounded-lg shadow-md text-center flex flex-col items-center justify-center gap-2 cursor-pointer lg:w-1/3">
                    <div>
                        <PiPhoneCallFill className='w-8 h-8 text-primary' aria-label="Icône Téléphone"/>
                    </div>
                    <p className='text-primary hidden md:block'>02 43 45 50 23</p>
                </a>
                <a href="mailto:ferrand87@orange.fr" target='_blank' rel="noreferrer" className="bg-white p-4 md:p-10 md:h-32 rounded-full lg:rounded-lg shadow-md text-center flex flex-col items-center justify-center gap-2 cursor-pointer lg:w-1/3">
                    <div>
                        <IoMail className='w-8 h-8 text-primary' aria-label="Icône Email"/>
                    </div>
                    <p className='text-primary hidden md:block'>ferrand87@orange.fr</p>
                </a>
            </div>
                
            {/* FILLOUT */}
            <div className='h-[1200px] lg:h-[750px] overflow-hidden shadow-md rounded-lg'>
                <FilloutStandardEmbed className="w-full" filloutId="j15hCCPGAEus"/>
            </div>
        </div>
    );
}