import { useState } from "react";
import { Collapse } from "react-collapse";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";

export default function FaqGroup({ faq }) {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Ferme si déjà ouvert
  };

  return (
    <div className="mb-20">
      {faq.map((faq, index) => (
        <div key={index} className="mb-5 bg-white p-4 rounded-xl shadow-md">
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => toggleFaq(index)}
          >
            <h3 className="font-medium text-base" dangerouslySetInnerHTML={{ __html: faq.question }}></h3>
            {openIndex === index ? (
              <FaChevronUp className="text-primary" aria-label="Flèche vers le haut" />
            ) : (
              <FaChevronDown className="text-primary" aria-label="Flèche vers le bas" />
            )}
          </div>
          <Collapse isOpened={openIndex === index}>
            <p className="mt-3 pl-3" dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
          </Collapse>
        </div>
      ))}
    </div>
  );
}
