import React, { useRef, useState, useEffect } from "react";
import articles from "../data/articles";
import reviews from "../data/reviews";
import MapIframe from '../Maps';

// SWIPER
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// FILLOUT
import { FilloutPopupEmbed } from "@fillout/react";
import "@fillout/react/style.css";

// ICONS
import { FaArrowRight, FaArrowLeft, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { IoIosStar } from "react-icons/io";

function ReviewCard({ author, review, rating }) {
    // Limiter l'affichage à 150 caractères
    const reviewPreview = review.length > 150 ? review.substring(0, 150) + "..." : review;

    const renderStars = (rating) => {
        const fullStars = Math.floor(rating);
        const stars = [];
        for (let i = 0; i < 5; i++) {
            stars.push(
                i < fullStars ? (
                    <IoIosStar
                        className="text-yellow-400"
                        aria-label="Étoile de notation"
                        key={i}
                    />
                ) : (
                    <IoIosStar
                        className="text-gray-600"
                        aria-label="Étoile de notation"
                        key={i}
                    />
                )
            );
        }
        return stars;
    };

    return (
        <div className="review-card flex flex-col items-center justify-end gap-2 h-full">
            <h3 className="text-primary font-semibold text-sm md:text-base">
                {author}
            </h3>
            <p className="flex gap-1">{renderStars(rating)}</p>
            <div className="flex-grow flex items-center justify-center">
                <p className="text-center">{reviewPreview}</p>
            </div>

            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(review) }}
            />
        </div>
    );
}

function Accueil({ headerHeight }) {
    const prevRefService = useRef(null);
    const nextRefService = useRef(null);

    const [isOpen, setIsOpen] = useState(false);

    const latestArticles = articles.slice(-3);

    useEffect(() => {
            document.title = `Garage Ferrand - Bosch Car Service`;
    
            let metaDescription = document.querySelector("meta[name='description']");
    
            if (metaDescription) {
                metaDescription.setAttribute("content", "Vous chercher un garage automobile pour faire réparer votre voiture ? Le garage Ferrand sera prêt a vous accueillir pour tout types de problèmes.");
            } else {
                // Sinon, on la crée et on l'ajoute au <head>
                metaDescription = document.createElement("meta");
                metaDescription.name = "description";
                metaDescription.content = "Vous chercher un garage automobile pour faire réparer votre voiture ? Le garage Ferrand sera prêt a vous accueillir pour tout types de problèmes.";
                document.head.appendChild(metaDescription);
            }
        }, []);

    return (
        <main>
            {/* HEROS SECTION */}
            <section className="grid lg:grid-cols-2 pt-32 lg:pt-0"
            style={{ height: `calc(100vh - ${headerHeight}px)`}}>
                {/* TEXT */}
                <div className="lg:ml-44 3xl:ml-64 mt-10 md:mt-0 flex flex-col justify-center text-center lg:text-left gap-4">
                    <h1 className="text-2xl md:text-3xl lg:text-4xl 3xl:text-5xl font-medium text-primary">Garage Ferrand Le Bailleul <br />Bosch Car Service</h1>
                    <p className="text-gray-700 px-10 md:px-24 lg:px-0 leading-7">
                        Situé au <strong>Bailleul</strong> près du <strong>Pôle Santé</strong>, notre garage <strong>Bosch Car Service</strong> est spécialisé dans le <strong>dépannage autoroute</strong>, l'<strong>entretien</strong> et le <strong>diagnostic</strong> de véhicules toutes marques. Nous proposons une gamme complète de services pour répondre à tous vos besoins.
                    </p>
                    <div className="flex flex-col md:flex-row gap-4 justify-center lg:justify-start mx-16 md:mx-0">
                        <a href="/services" className="px-6 py-3 rounded-full shadow-lg lg:text-center text-sm border border-primary bg-primary text-white">Découvrir nos services</a>
                        <a href="/contact" className="px-6 py-3 rounded-full shadow-lg lg:text-center text-sm border border-primary bg-white  text-primary lg:hover:bg-primary lg:hover:text-white transition ">Nous contacter</a>
                    </div>
                </div>

                {/* IMAGE */}
                <div className="lg:overflow-hidden hidden lg:block">
                    <img src="/images/mecanicien-enleve-pare-brise.webp" alt="Réparation de voiture" className="w-full h-full object-cover clip-triangle" />
                </div>

                <div className="block lg:hidden mt-10 md:mt-0">
                    <div className="background-heros">
                        
                    </div>
                    <div className="relative md:hidden">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1440 320"
                            className="absolute inset-x-0 -bottom-4"
                            >
                            <path
                                fill="#fafafa"
                                fillOpacity="1"
                                d="M0,128L80,138.7C160,149,320,171,480,181.3C640,192,800,192,960,165.3C1120,139,1280,85,1360,58.7L1440,32L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
                            ></path>
                        </svg>
                    </div>
                </div>

                {/* Statistics Section */}
                <div className="block lg:absolute lg:-bottom-32 px-10 md:px-24 xl:px-64 2xl:px-96 w-full bg-primary-light mt-0 mb-12 md:mt-12 py-0 md:py-8">
                    <div className="container mx-auto px-6 lg:px-0 grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
                        <div className="flex flex-col justify-center bg-white shadow-2xl rounded-lg py-8 lg:hover:scale-105 lg:hover:shadow-2xl lg:shadow-primary lg:hover:shadow-primary transition transform duration-300">
                            <span className="text-3xl font-medium text-primary">2007</span>
                            <p className="mt-2">Création du garage</p>
                        </div>
                        <div className="flex flex-col justify-center bg-white shadow-2xl rounded-lg py-8 lg:hover:scale-105 lg:hover:shadow-2xl lg:shadow-primary lg:hover:shadow-primary transition transform duration-300">
                            <span className="text-3xl font-medium text-primary">34</span>
                            <p className="mt-2">Services</p>
                        </div>
                        <div className="flex flex-col justify-center bg-white shadow-2xl rounded-lg py-8 lg:hover:scale-105 lg:hover:shadow-2xl lg:shadow-primary lg:hover:shadow-primary transition transform duration-300">
                            <span className="text-3xl font-medium text-primary">~1700</span>
                            <p className="mt-2">Clients/an</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* SERVICES SECTION */}
            <section className="mt-[800px] md:mt-0 lg:mt-48">
                <h2 className="text-center text-2xl md:text-3xl 2xl:text-4xl font-medium text-primary">Nos services</h2>
                <p className='mt-2 text-center pb-8 px-10 md:px-24 xl:px-72 3xl:px-96 leading-7 text-gray-700'>Découvrez l'ensemble de nos services dédiés à l'<strong>entretien</strong> et à la <strong>réparation</strong> de votre véhicule. Du <strong>diagnostic</strong> automobile à la <strong>vidange</strong>, en passant par la <strong>révision</strong> et le <strong>dépannage</strong>, nous proposons des solutions adaptées à toutes les marques pour garantir votre sécurité et prolonger la durée de vie de votre voiture.</p>

                <div className='container min-w-[300px] md:w-[700px] lg:w-[1000px] 3xl:w-[1200px] flex items-center justify-center px-24 mx-auto relative'>
                    <button ref={prevRefService} className="absolute left-10 top-1/2 z-10 -translate-y-1/2 bg-primary text-white p-2 rounded-full mr-10">
                        <FaArrowLeft aria-label="Flèche vers la gauche"/>
                    </button>
                    <button ref={nextRefService} className="absolute right-10 top-1/2 z-10 -translate-y-1/2 bg-primary text-white p-2 rounded-full ml-10">
                        <FaArrowRight aria-label="Flèche vers la droite"/>
                    </button>
                    <Swiper
                        modules={[Navigation]}
                        slidesPerView={1}
                        spaceBetween={30}
                        breakpoints={{
                            600: {
                                slidesPerView: 2,
                            },
                            1200: {
                                slidesPerView: 3,
                            },
                        }}
                        navigation={{
                            prevEl: prevRefService.current, // Link to previous button
                            nextEl: nextRefService.current, // Link to next button
                        }}
                        onInit={(swiper) => {
                            swiper.params.navigation.prevEl = prevRefService.current;
                            swiper.params.navigation.nextEl = nextRefService.current;
                            swiper.navigation.init();
                            swiper.navigation.update();
                        }}
                        loop={true}
                        className="mySwiper h-56" 
                    >
                        <SwiperSlide>
                            <a href="/services/revision-vidange" className="bg-white p-10 h-52 rounded-lg shadow-md text-center flex flex-col items-center justify-center hover:cursor-pointer">
                                <img src="/images/vidange.webp" alt="Service de révision et vidange automobile au Garage Ferrand" className="mx-auto mb-4 w-16 h-16"/>
                                <p className="text-semibold">Révision / Vidange</p>
                            </a> 
                        </SwiperSlide>


                        <SwiperSlide>
                            <a href="/services/diagnostic" className="bg-white p-10 h-52 rounded-lg shadow-md text-center flex flex-col items-center justify-center hover:cursor-pointer">
                                <img src="/images/diagnostic.webp" alt="Service de diagnostic automobile au Garage Ferrand" className="mx-auto mb-4 w-16 h-16" />
                                <p className="text-semibold">Diagnostic</p>
                            </a> 
                        </SwiperSlide>

                        <SwiperSlide>
                            <a href="/services/climatisation" className="bg-white p-10 h-52 rounded-lg shadow-md text-center flex flex-col items-center justify-center hover:cursor-pointer">
                                <img src="/images/climatisation.webp" alt="Service de climatisation automobile au Garage Ferrand" className="mx-auto mb-4 w-16 h-16" />
                                <p className="text-semibold">Climatisation</p>
                            </a>
                        </SwiperSlide>

                        <SwiperSlide>
                            <a href="/services/pneumatique" className="bg-white p-10 h-52 rounded-lg shadow-md text-center flex flex-col items-center justify-center hover:cursor-pointer">
                                <img src="/images/pneumatique.webp" alt="Service de pneumatique automobile au Garage Ferrand" className="mx-auto mb-4 w-16 h-16" />
                                <p className="text-semibold">Pneumatique</p>
                            </a>
                        </SwiperSlide>

                        <SwiperSlide>
                            <a href="/services/freinage" className="bg-white p-10 h-52 rounded-lg shadow-md text-center flex flex-col items-center justify-center hover:cursor-pointer">
                                <img src="/images/freinage.webp" alt="Service de freinage automobile au Garage Ferrand" className="mx-auto mb-4 w-16 h-16" />
                                <p className="text-semibold">Freinage</p>
                            </a>
                        </SwiperSlide>

                        <SwiperSlide>
                            <a href="/services/reglage-geometrie" className="bg-white p-10 h-52 rounded-lg shadow-md text-center flex flex-col items-center justify-center hover:cursor-pointer">
                                <img src="/images/reglage-geometrie.webp" alt="Service de réglage géométrie automobile au Garage Ferrand" className="mx-auto mb-4 w-16 h-16" />
                                <p className="text-semibold">Réglage Géométrie</p>
                            </a>
                        </SwiperSlide>

                        <SwiperSlide>
                            <a href="/services/distribution" className="bg-white p-10 h-52 rounded-lg shadow-md text-center flex flex-col items-center justify-center hover:cursor-pointer">
                                <img src="/images/distribution.webp" alt="Service de distribution automobile au Garage Ferrand" className="mx-auto mb-4 w-16 h-16" />
                                <p className="text-semibold">Distribution</p>
                            </a>
                        </SwiperSlide>

                        <SwiperSlide>
                            <a href="/services/embrayage" className="bg-white p-10 h-52 rounded-lg shadow-md text-center flex flex-col items-center justify-center hover:cursor-pointer">
                                <img src="/images/embrayage.webp" alt="Service d'embrayage automobile au Garage Ferrand" className="mx-auto mb-4 w-16 h-16" />
                                <p className="text-semibold">Embrayage</p>
                            </a>
                        </SwiperSlide>

                        <SwiperSlide>
                            <a href="/services/pare-brise" className="bg-white p-10 h-52 rounded-lg shadow-md text-center flex flex-col items-center justify-center hover:cursor-pointer">
                                <img src="/images/pare-brise.webp" alt="Service de pare-brise automobile au Garage Ferrand" className="mx-auto mb-4 w-16 h-16" />
                                <p className="text-semibold">Pare-brise</p>
                            </a>
                        </SwiperSlide>

                        <SwiperSlide>
                            <a href="/services/eclairage" className="bg-white p-10 h-52 rounded-lg shadow-md text-center flex flex-col items-center justify-center hover:cursor-pointer">
                                <img src="/images/eclairage.webp" alt="Service d'éclairage automobile au Garage Ferrand" className="mx-auto mb-4 w-16 h-16" />
                                <p className="text-semibold">Eclairage</p>
                            </a>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className="flex items-center justify-center mt-4">
                    <a href="/services" className="bg-primary text-white px-6 py-3 rounded-full shadow-lg hover:bg-primary-dark transition lg:text-center">
                                Découvrir tous nos services
                    </a>
                </div>
            </section>
        
            {/* DEVIS */}
            <section className="md:container md:w-[600px] lg:w-[900px] 3xl:w-[1100px] md:h-[500px] mx-10 md:mx-auto bg-white shadow-2xl flex flex-col md:flex-row items-center justify-center rounded-lg mt-36">
                {/* IMAGE */}
                <img src="/images/lustrage.webp" alt="Réparation de voiture" className="w-full md:w-1/3 lg:w-1/2 h-72 md:h-full object-cover rounded-lg md:rounded-l-lg overflow-hidden"/>

                {/* TEXT */}
                <div className="flex flex-col gap-4 items-center justify-center w-2/3 lg:w-1/2 mt-8 md:mt-0">
                    <h2 className="text-center text-2xl md:text-3xl 2xl:text-4xl font-medium text-primary">Devis en ligne</h2>
                    <p className="text-gray-700 leading-7 text-center md:mx-10">
                        Nous vous proposons des devis pour des services essentiels tels que la <strong>vidange / filtre</strong>, la <strong>climatisation</strong>, le <strong>freinage</strong>, la <strong>pneumatique</strong>, <strong>réglage de géométrie</strong>, <strong>distribution</strong>, <strong>lecture défaut</strong> et l'<strong>éclairage</strong>. Pour tout autre service, merci de venir directement au garage.
                    </p>

                    <button className="px-6 py-3 rounded-full mb-10 md:mb-0 shadow-lg text-center text-sm border border-primary bg-white text-primary lg:hover:bg-primary lg:hover:text-white transition" onClick={() => {
                        if (localStorage.getItem("cookieConsent") === "accepted" && window.gtag) {
                            window.gtag("event", "open_form", {
                                event_category: "Formulaire",
                                event_label: "Popup Fillout ouverte"
                            });
                        }
                        setIsOpen(true);
                    }}>Commencer le devis</button>

                    {isOpen && (
                        <FilloutPopupEmbed
                        filloutId="i1DrDgYqhgus"
                        onClose={() => setIsOpen(false)}
                        />
                    )}
                </div>
            </section>

            {/* ACTUALITES SECTION */}
            <section id="actualites" className="mt-36">
                <h2 className="text-center text-2xl md:text-3xl 2xl:text-4xl font-medium text-primary">Nos actualités</h2>
                <div className='container min-w-[300px] md:w-[700px] lg:w-[1000px] 3xl:w-[1200px] flex items-center justify-center px-10 mx-auto relative'>
                    <Swiper
                        modules={[Pagination]} 
                        spaceBetween={16} // Espacement entre les slides
                        slidesPerView={1} // Une seule slide visible par défaut
                        pagination={{
                            clickable: true, // Pagination cliquable
                        }}
                        breakpoints={{
                        768: {
                            slidesPerView: 3, // Affiche 3 slides sur desktop
                            spaceBetween: 24, // Augmente l'espacement entre les slides
                            pagination: false, // Désactive la pagination sur desktop
                        },
                        }}
                        className="mt-6"
                    >
                        {latestArticles.map((article) => (
                            <SwiperSlide key={article.id}>
                                <div className="bg-white flex flex-col items-center justify-between rounded-xl overflow-hidden" onClick={() => window.location.href = `/actualites/${article.slug}`}>
                            
                                        <img
                                            src={article.image}
                                            alt={article.title}
                                            className="w-full h-48 object-cover"
                                        />
                                        <div className="p-4">
                                            <p className="text-sm text-gray-500">{article.date}</p>
                                            <h3 className="text-lg font-medium mt-2 text-gray-900">
                                            {article.title}
                                            </h3>
                                            <p className="text-gray-600 mt-2 text-sm line-clamp-3">
                                            {article.subtitle}
                                            </p>
                                            <a href={`/actualites/${article.slug}`}
                                            className="text-primary my-4 inline-block font-medium"
                                            >
                                            Lire plus
                                            </a>
                                        </div>
                               
                                </div>
                            </SwiperSlide>
                            ))}
                    </Swiper>
                </div>
                <div className="flex items-center justify-center mt-6">
                    <a href="/actualites" className="bg-primary text-white px-6 py-3 rounded-full shadow-lg hover:bg-primary-dark transition lg:text-center">
                                Découvrir tous nos articles
                    </a>
                </div>
            </section>

            {/* CONTACT SECTION */}
            <section className="mt-36">
                <h2 className="text-center text-2xl md:text-3xl 2xl:text-4xl font-medium text-primary">Nous contacter</h2>
                <div className='md:container md:w-[600px] lg:w-[900px] 3xl:w-[1100px] mx-10 md:mx-auto flex flex-col lg:flex-row gap-10 items-center justify-center mt-4 bg-white rounded-lg shadow-md'>
                    {/* left */}
                    <div className="w-full lg:w-1/2">
                        <MapIframe/>
                    </div>
            
                    {/* right */}
                    <div className='w-full lg:w-1/2 flex flex-col justify-center lg:items-start gap-4 px-10'>
                        <h3 className='text-primary font-medium text-center lg:text-left'>Nos horaires d'ouverture : Du Lundi au Vendredi, de 8h00 à 19h00</h3>
                        <div className="flex lg:flex-col gap-4">
                            <a href="https://www.google.fr/maps/place/Garage+Pascal+Ferrand/@47.7733533,-0.2146748,17z/data=!4m6!3m5!1s0x481117e7ef5eb399:0x53afc46dbd157bbd!8m2!3d47.7733497!4d-0.2120999!16s%2Fg%2F1tdq0h_y?entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noreferrer" className=" w-full flex items-center justify-center lg:justify-start lg:gap-4 cursor-pointer">
                                <FaMapMarkerAlt className='w-8 h-8 md:w-6 md:h-6 text-primary' aria-label="Icône Localisation"/>
                                <p className='text-primary hidden lg:block'>30 rue du Portugal, 72200 Le Bailleul</p>
                            </a>
                            <a href="mailto:ferrand87@orange.fr" className="w-full flex items-center justify-center lg:justify-start lg:gap-4 cursor-pointer">
                                <IoMail className='w-8 h-8 md:w-6 md:h-6 text-primary' aria-label="Icône Email"/>
                                <p className='text-primary hidden lg:block'>ferrand87@orange.fr</p>
                            </a>
                            <a href="tel:0243455023" className="w-full flex items-center justify-center lg:justify-start lg:gap-4 cursor-pointer">
                                <FaPhoneAlt className='w-8 h-8 md:w-6 md:h-6 text-primary' aria-label="Icône Téléphone"/>
                                <p className='text-primary hidden lg:block'>02 43 45 50 23</p>
                            </a>
                        </div>
                        
                        <div className="flex justify-center w-full">
                            <a href="/contact" className="px-6 py-3 w-full rounded-full mb-10 md:mb-0 shadow-lg text-center text-sm border border-primary bg-white text-primary lg:hover:bg-primary lg:hover:text-white transition">Nous contacter</a>
                        </div>
                    </div>
                    
                </div>
            </section>

            {/* REVIEWS SECTION */}
            <section id="reviews" className="mt-36 mb-36">
                <h2 className="text-center text-2xl md:text-3xl 2xl:text-4xl font-medium text-primary">Ce que disent nos clients</h2>

                <div className="md:container md:w-[600px] lg:w-[900px] 3xl:w-[1100px] h-[600px] md:h-[550px] xl:h-[500px] mx-10 md:mx-auto bg-white shadow-2xl flex flex-col-reverse lg:flex-row items-center justify-between rounded-lg mt-10">
                    <div className="flex flex-col gap-10">
                        <div className='mt-10 xl:mt-0 w-[200px] md:w-[450px] xl:w-[350px] 3xl:w-[500px] mx-12'>
                            <Swiper
                                modules={[Autoplay, Pagination]} 
                                slidesPerView={1} 
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}   
                                pagination={{
                                    clickable: true,
                                    el: '.custom-pagination',
                                }}                       
                            >
                            {reviews.map((review) => (
                            <SwiperSlide key={review.id}>
                                    <div>
                                        <ReviewCard 
                                            author={review.author.name}
                                            review={review.reviewBody}
                                            rating={review.reviewRating.ratingValue}
                                        />
                                    </div>
                                </SwiperSlide>
                            ))}
                            </Swiper>    
                            <div className="custom-pagination mt-4"></div>
                                               
                        </div>
                        <div className="flex items-center justify-center mb-4 md:mb-10 lg:mb-0">
                            <a href="https://www.google.fr/maps/place/Garage+Pascal+Ferrand/@47.7733533,-0.2146748,17z/data=!4m8!3m7!1s0x481117e7ef5eb399:0x53afc46dbd157bbd!8m2!3d47.7733497!4d-0.2120999!9m1!1b1!16s%2Fg%2F1tdq0h_y?entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D" target='_blank' rel="noreferrer"  className="bg-primary text-white px-6 py-3 rounded-full shadow-lg hover:bg-primary-dark transition lg:text-center">Laisser un avis</a>
                        </div>
                    </div>
                    <img src="/images/mecanicien-remplacement-courroie-distribution.webp" alt="Réparation de voiture" className="w-full xl:w-1/2 md:h-full object-cover rounded-lg md:rounded-l-lg overflow-hidden"/>
                </div>
            </section>
        </main>
    )
}

export default Accueil;